import { IResourceComponentsProps } from "@refinedev/core";
import { Card, Col, Row, Typography, notification } from "antd";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  StreaksInsights,
  getCommunityStreaksInsightsUseCase,
} from "usecases/community/getCommunityStreaksInsightsUseCase";

export const CommunityStreaks: FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const [streaksInsights, setStreaksInsights] =
    useState<StreaksInsights | null>(null);

  useEffect(() => {
    if (!streaksInsights && id) {
      const fetchData = async () => {
        try {
          const data = await getCommunityStreaksInsightsUseCase(id);
          setStreaksInsights(data);
        } catch (error: any) {
          notification.error({
            message: error?.response?.data?.message,
            description: error?.response?.status,
          });
        }
      };
      fetchData();
    }
  }, [streaksInsights, id]);

  const { Title } = Typography;
  return (
    <>
      <Title level={3} style={{ marginBottom: "2rem" }}>
        Community Streaks
      </Title>
      <Row gutter={30} style={{ paddingLeft: "1rem" }}>
        <Col span="50%" style={{ marginBottom: "2rem" }}>
          <Card title="Listening daily" bordered={true}>
            {streaksInsights?.enrolledUsersCount || "--"}
          </Card>
        </Col>
        <Col span="50%">
          <Card title="Verified listens" bordered={true}>
            {streaksInsights?.totalStreaksCount || "--"}
          </Card>
        </Col>
      </Row>
    </>
  );
};
