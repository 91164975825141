export class PhoneValidator {
  private regex: RegExp = /^\+[1-9]\d{1,14}$/gm;

  validate(username: string): boolean {
    return this.regex.test(username);
  }

  errorMessage(): string {
    return "Please enter a valid phone number.";
  }
}
