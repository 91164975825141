import { IResourceComponentsProps } from "@refinedev/core";

import { ImageField, List, useTable } from "@refinedev/antd";

import {
  Breadcrumb,
  Button,
  Checkbox,
  Modal,
  Radio,
  RadioChangeEvent,
  Table,
  Tooltip,
  Typography,
  notification,
} from "antd";

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  UserDeleteOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import { getCommunityFandomIdUseCase } from "usecases/community/getCommunityFandomId";
import { removeCommunityChannelMessageUseCase } from "usecases/community/removeCommunityChannelMessageUseCase";
import { removeCommunitySessionMessageUseCase } from "usecases/community/removeCommunitySessionMessageUseCase";
import {
  CommunityReportStatus,
  updateCommunityReportStatusUseCase,
} from "usecases/community/updateCommunityReportStatusUseCase";
import { blockUserUseCase } from "usecases/user/blockUserUseCase";

export const CommunityReportsList: React.FC<IResourceComponentsProps> = () => {
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const urlParams = useParams();
  const [openModal, setOpenModal] = useState(false);

  const [fandomId, setFandomId] = useState<string | null>(null);
  const [reportsChannelId, setReportsChannelId] = useState<string | null>(
    urlParams.id || null
  );

  const [paginatedReports, setPaginatedReports] = useState<any>();
  const [paginationNextID, setPaginationNextID] = useState<string | null>(null);
  const [paginationPrevID, setPaginationPrevID] = useState<string | null>(null);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);

  const {
    tableProps,
    tableQueryResult: { data },
  } = useTable<any>({
    resource: "communityReports",
    pagination: { mode: "off" },
    meta: {
      id: reportsChannelId,
      prev: paginationPrevID,
      next: paginationNextID,
    },
    queryOptions: {
      retry: 0,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (urlParams.id && !fandomId) {
        const fandomIdRequest = await getCommunityFandomIdUseCase(urlParams.id);
        setFandomId(fandomIdRequest.data.id);
      }
    };

    fetchData();
  }, [urlParams.id]);

  useEffect(() => {
    if (data?.data) {
      const reports = data?.data;
      setPaginatedReports(reports);

      setPrevBtnEnabled(!!data?.cursor.prev);
      setNextBtnEnabled(!!data?.cursor.next);
    }
  }, [data?.data]);

  const handleRadioBtnSelectionChange = async (e: RadioChangeEvent) => {
    setPaginationNextID(null);
    setPaginationPrevID(null);

    const key = e.target.value;
    if (key === "sessions" && urlParams.id) {
      setReportsChannelId(urlParams.id);
    }

    if (key === "fanverse") {
      setReportsChannelId(fandomId);
    }
  };

  function formatUnixTimestamp(timestamp: number) {
    const date = new Date(timestamp);
    return date.toLocaleString(undefined, {
      dateStyle: "short",
      timeStyle: "short",
      hourCycle: "h23",
      timeZone: "UTC",
    });
  }

  const blockUser = async (id: string, reportId: string) => {
    try {
      await blockUserUseCase(id);
      notification.success({
        message: "User successfully deleted",
      });
      await markReportStatusAsSolved(reportId);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message,
        description: error?.response?.status,
      });
    }
  };

  const deleteChannelMessage = async (
    channelId: string,
    messageId: string,
    reportId: string
  ) => {
    try {
      await removeCommunityChannelMessageUseCase(channelId, messageId);
      notification.success({
        message: "Channel message successfully deleted",
      });
      await markReportStatusAsSolved(reportId);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message,
        description: error?.response?.status,
      });
    }
  };

  const deleteSessionMessage = async (
    sessionId: string,
    messageId: string,
    reportId: string
  ) => {
    try {
      await removeCommunitySessionMessageUseCase(sessionId, messageId);
      notification.success({
        message: "Session message successfully deleted",
      });
      await markReportStatusAsSolved(reportId);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message,
        description: error?.response?.status,
      });
    }
  };

  const markReportStatusAsSolved = async (reportId: string) => {
    try {
      await updateCommunityReportStatusUseCase(
        reportId,
        CommunityReportStatus.SOLVED
      );
      setPaginatedReports(
        paginatedReports.map((report: any) =>
          report.id === reportId
            ? { ...report, status: CommunityReportStatus.SOLVED }
            : report
        )
      );
      notification.success({
        message: "Report marked as solved",
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message,
        description: error?.response?.status,
      });
    }
  };

  const { Title } = Typography;
  return (
    <>
      <Title level={3}>Community Reports</Title>
      <Radio.Group
        onChange={handleRadioBtnSelectionChange}
        defaultValue="sessions"
        size="middle"
        style={{
          width: "100%",
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
      >
        <Radio.Button
          value="sessions"
          style={{ width: "50%", textAlign: "center" }}
        >
          Sessions
        </Radio.Button>
        <Radio.Button
          value="fanverse"
          style={{ width: "50%", textAlign: "center" }}
        >
          Fanverse
        </Radio.Button>
      </Radio.Group>
      <List
        breadcrumb={
          <div style={{ display: "flex", columnGap: "10px" }}>
            <ArrowLeftOutlined
              style={{ color: "gray", cursor: "pointer" }}
              onClick={() => {
                navigate("/communities");
              }}
            />
            <Breadcrumb
              items={[
                {
                  title: "Communities",
                  href: "/communities",
                },
                {
                  title: "Reports",
                },
              ]}
            />
          </div>
        }
      >
        <Table
          {...tableProps}
          dataSource={paginatedReports}
          rowKey="id"
          key="table"
          ref={tableRef}
          pagination={false}
          rowClassName={(record) =>
            record.reported.deletedAt ||
            record.status === CommunityReportStatus.SOLVED
              ? "solved-report"
              : ""
          }
        >
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column
            title="Solved"
            render={(value) => (
              <Tooltip placement="top" title="mark report as solved">
                <Checkbox
                  checked={
                    value.status === CommunityReportStatus.SOLVED ||
                    value.reported.deletedAt
                  }
                  disabled={
                    value.status === CommunityReportStatus.SOLVED ||
                    value.reported.deletedAt
                  }
                  onClick={async () => {
                    await markReportStatusAsSolved(value.id);
                  }}
                />
              </Tooltip>
            )}
          />
          <Table.Column dataIndex="reason" title="Reason" />
          <Table.Column
            dataIndex="reporter"
            title="Reporter"
            render={(value) => value.username}
          />
          <Table.Column
            dataIndex="reported"
            title="User"
            render={(value) => value.owner.username}
          />
          <Table.Column
            dataIndex="reported"
            title="Message ID"
            render={(value) => value.id}
          />
          <Table.Column
            dataIndex="reported"
            title="Sent at"
            render={(value) => formatUnixTimestamp(value.sentAt)}
          />
          <Table.Column
            dataIndex="reported"
            title="Deleted at"
            render={(value) =>
              value.deletedAt && formatUnixTimestamp(value.deletedAt)
            }
          />
          <Table.Column
            dataIndex="reported"
            title="Text"
            render={(value) => {
              return {
                props: {
                  style: {
                    maxWidth: "15rem",
                  },
                },
                children: <div>{value.text}</div>,
              };
            }}
          />
          <Table.Column
            dataIndex="reported"
            title="Multimedia"
            align="center"
            width={"20%"}
            render={(value) => {
              switch (value.kind) {
                case "image":
                  return (
                    <ImageField
                      value={value?.multimedia?.formats?.webp?.thumbnail}
                      width={150}
                      preview={{
                        src: value?.multimedia?.formats?.webp?.main,
                      }}
                    />
                  );
                case "video":
                  return (
                    <ReactPlayer
                      url={value?.multimedia?.formats?.hls?.main}
                      controls
                      width={150}
                      style={{ transform: "translateX(50%)" }}
                    />
                  );
                case "audio":
                  return (
                    <ReactPlayer
                      url={value?.multimedia?.formats?.hls?.main}
                      controls
                      width={"80%"}
                      height={35}
                    />
                  );
                default:
                  return null;
              }
            }}
          />
          <Table.Column
            title="Actions"
            width={"5%"}
            render={(value) => {
              if (
                value.status === CommunityReportStatus.SOLVED ||
                value.reported.deletedAt
              ) {
                return null;
              }

              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Tooltip placement="left" title="delete message">
                    <Button
                      prefixCls={"ant-btn"}
                      style={{ marginBottom: "0.5rem" }}
                      onClick={async () => {
                        if (
                          reportsChannelId &&
                          value?.reported?.parentId === reportsChannelId
                        ) {
                          await deleteChannelMessage(
                            value.reported.parentId,
                            value.reported.id,
                            value.id
                          );
                          return;
                        }
                        await deleteSessionMessage(
                          value.reported.parentId,
                          value.reported.id,
                          value.id
                        );
                      }}
                    >
                      <DeleteOutlined style={{ color: "black" }} />
                    </Button>
                  </Tooltip>
                  <Tooltip placement="left" title="delete and block user">
                    <Button
                      prefixCls={"ant-btn"}
                      onClick={(e) => {
                        setOpenModal(true);
                      }}
                    >
                      <UserDeleteOutlined style={{ color: "red" }} />
                    </Button>
                  </Tooltip>
                  <Modal
                    title="Delete and BLOCK user from sesh"
                    open={openModal}
                    onOk={async () => {
                      await blockUser(value.reported.owner?.id, value.id);
                      setOpenModal(false);
                    }}
                    onCancel={() => setOpenModal(false)}
                    okText="Confirm"
                    cancelText="Cancel"
                  >
                    <p>
                      <WarningOutlined style={{ color: "#E6B300" }} />
                      This action cannot be reversed
                    </p>
                    <span>
                      Recommendations: Verify the ratio between the user's
                      reports and the user's activity in case the severity of
                      the report is not high.
                    </span>
                  </Modal>
                </div>
              );
            }}
          />
        </Table>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "flex-end",
          }}
        >
          <div>
            <Button
              icon={
                <ArrowLeftOutlined
                  style={{
                    color: `${prevBtnEnabled}? "black" : "lightgrey"`,
                  }}
                />
              }
              style={{ width: "3rem" }}
              onClick={() => {
                setPaginationPrevID(data?.cursor.prev);
                setPaginationNextID(null);
              }}
              disabled={!prevBtnEnabled}
            ></Button>
            <Button
              icon={
                <ArrowRightOutlined
                  style={{
                    color: `${nextBtnEnabled}? "black" : "lightgrey"`,
                  }}
                />
              }
              style={{ width: "3rem" }}
              onClick={() => {
                setPaginationNextID(data?.cursor.next);
                setPaginationPrevID(null);
              }}
              disabled={!nextBtnEnabled}
            ></Button>
          </div>
        </div>
      </List>
    </>
  );
};
