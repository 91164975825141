import {updateCommunity} from "../../services/community/updateCommunity";
import {updateCommunityImage} from "../../services/community/updateCommunityImage";
import {updateCommunityShareLink} from "../../services/community/updateCommunityShareLink";
import {CommunityUpdateDTO} from "../../interfaces";

export async function updateCommunityUseCase(id: string, variables: CommunityUpdateDTO) {
    await updateCommunity(id, variables);

    if (variables.shareLink !== undefined) {
        await updateCommunityShareLink(id, variables.shareLink);
    }
    if (variables.image !== undefined && variables.image !== null) {
        await updateCommunityImage(id, variables.image)
    }
}