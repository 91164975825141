import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { Create, useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Upload,
  notification,
} from "antd";
import { FC, useEffect, useState } from "react";
import { SpotifyIdValidator } from "services/community/spotifyIdValidator";
import { CommunityCreateDTO } from "../../interfaces";

export const CommunityCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, form } = useForm<CommunityCreateDTO>();

  const [isFileFormatValid, setIsFileFormatValid] = useState(true);
  const [isFileSizeValid, setIsFileSizeValid] = useState(true);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const [spotifyId, setSpotifyId] = useState<string>("");
  const [showSpotifyUrlWarning, setShowSpotifyUrlWarning] = useState(false);
  const [
    spotifyIdFeatureWarningDiscarded,
    setSpotifyIdFeatureWarningDiscarded,
  ] = useState(false);

  const [isHidden, setIsHidden] = useState(true);
  const [isSearchable, setIsSearchable] = useState(false);

  useEffect(() => {
    setIsHidden(form.getFieldValue("hidden"));
    setIsSearchable(form.getFieldValue("searchable"));
  }, [form.getFieldValue("hidden"), form.getFieldValue("searchable")]);

  useEffect(() => {
    if (
      (!isHidden || isSearchable) &&
      !spotifyId &&
      !spotifyIdFeatureWarningDiscarded
    ) {
      Modal.warning({
        title: "Warning",
        content:
          "Community will be accesible for users and, without a Spotify ID, it will not have the streaks feature enabled",
        onOk() {
          setSpotifyIdFeatureWarningDiscarded(true);
        },
      });
    }
  }, [isHidden, isSearchable, spotifyId, spotifyIdFeatureWarningDiscarded]);

  const handleFormSubmit = (values: any) => {
    if (!isFileFormatValid) {
      notification.error({
        message: "Invalid file type",
        description: "You can only upload .jpg file!",
      });
      return false;
    }
    if (!isFileSizeValid) {
      notification.error({
        message: "Invalid size",
        description: "Please upload a file no larger than 2MB.",
      });
      return false;
    }
    if (uploadedFile) {
      form.setFieldsValue({
        image: uploadedFile,
      });
    }
    return true;
  };

  return (
    <Create saveButtonProps={{ style: { display: "none" } }}>
      <Form {...formProps} layout="vertical" onSubmitCapture={handleFormSubmit}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Owner Username"
          name="ownerUsername"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Image"
          name="image"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Upload
            name="image"
            listType="picture"
            accept=".jpg"
            maxCount={1}
            beforeUpload={(file) => {
              if (file.type !== "image/jpeg" || !file.name.endsWith(".jpg")) {
                setIsFileFormatValid(false);
                return true; // Unexpectedly, this will show file in red color as an error
              }

              if (file.size > 2 * 1024 * 1024) {
                setIsFileSizeValid(false);
                return true; // Unexpectedly, this will show file in red color as an error
              }

              setUploadedFile(file);

              setIsFileSizeValid(true);
              setIsFileFormatValid(true);
              return false; // Unexpectedly, this will show file in gray color as a success
            }}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        <div
          style={{
            marginTop: "-10px",
            marginBottom: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: "12px" }}>
            Only <b>.jpg</b> files are permitted for the image. (.jpeg, .png,
            .webp and any other extension <b>are NOT allowed</b>)
          </span>
          <span style={{ fontSize: "12px" }}>
            Maximum file size is <b>2MB</b>.
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#f9fbfc",
            padding: "1rem 1rem 0",
            marginBottom: "1rem",
          }}
        >
          <Form.Item
            label="Hidden"
            name="hidden"
            valuePropName="checked"
            rules={[
              {
                required: false,
              },
            ]}
            initialValue={true}
          >
            <Checkbox
              title={"Hidden"}
              name="hidden"
              defaultChecked={true}
              onChange={(event) => setIsHidden(event.target.checked)}
            />
          </Form.Item>
          <Form.Item
            label="Searchable"
            name="searchable"
            valuePropName="checked"
            rules={[
              {
                required: false,
                type: "boolean",
              },
            ]}
          >
            <Checkbox
              title={"Searchable"}
              name="searchable"
              onChange={(event) => setIsSearchable(event.target.checked)}
            />
          </Form.Item>
          <Form.Item
            label="Verified"
            name="verified"
            valuePropName="checked"
            rules={[
              {
                required: false,
              },
            ]}
            initialValue={true}
          >
            <Checkbox
              title={"Verified"}
              name="verified"
              defaultChecked={true}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="Spotify ID"
          name="spotifyId"
          rules={[
            {
              validator: async (_, value: string) => {
                const validator = new SpotifyIdValidator();
                if (validator.validate(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(validator.errorMessage()));
              },
            },
          ]}
          style={{ marginBottom: showSpotifyUrlWarning ? "0" : "2rem" }}
        >
          <Input
            onChange={(e) => {
              setShowSpotifyUrlWarning(true);
              setSpotifyId(e.target.value);
            }}
          />
        </Form.Item>
        {showSpotifyUrlWarning && (
          <p style={{ fontSize: "12px", marginBottom: "2rem" }}>
            before saving changes make sure the profile url is correct{" "}
            <a
              href={`https://open.spotify.com/artist/${
                spotifyId || form.getFieldValue("spotifyId")
              }`}
              target="_blank"
              rel="noreferrer"
            >{`https://open.spotify.com/artist/${
              spotifyId || form.getFieldValue("spotifyId")
            }`}</a>
          </p>
        )}
        <Form.Item
          label="Share link name"
          name="shareLink"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder="thechange" />
        </Form.Item>
        <div
          style={{
            marginTop: "-15px",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: "12px" }}>
            This name should match with the url´s ending name of the community´s
            dynamic link in Firebase and the artist web page
            (https://joinsesh.app/artist/<b>thechange</b>)
          </span>
        </div>
        <Form.Item>
          <Button
            icon={<SaveOutlined />}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ float: "right" }}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Create>
  );
};
