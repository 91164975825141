import axios from "axios";
import { apiUrl } from "../../../App";

export async function getCommunityRolesListUseCase(communityId: string) {
  return await axios.get(`${apiUrl}/admin/chat/channels/${communityId}/users`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  });
}
