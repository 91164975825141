import axios from "axios";
import { apiUrl } from "../../App";

export async function getCommunityFandomIdUseCase(communityId: string) {
  const url = `${apiUrl}/admin/chat/channels/${communityId}/fandom`;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  return axios.get(url, config);
}
