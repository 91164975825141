import {
  ErrorComponent,
  RefineThemes,
  Sider,
  ThemedLayoutV2,
  ThemedSiderV2,
} from "@refinedev/antd";
import { AuthBindings, Authenticated, Refine } from "@refinedev/core";
import routerProvider, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { ConfigProvider, notification } from "antd";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import "@refinedev/antd/dist/reset.css";
import "app.css";

import axios from "axios";
import { CommunityPostsList } from "pages/communities/posts/list";
import { CommunityReportsList } from "pages/communities/reports/list";
import { CommunityUserModeratorConfigEdit } from "pages/communities/roles/moderatorConfig/edit";
import { CommunitySessions } from "pages/communities/sessions";
import { TermsAndConditionsSessionsList } from "pages/communities/sessions/terms-and-conditions/list";
import { CommunityStreaks } from "pages/communities/streaks";
import { Login } from "pages/login";
import apiDataProvider from "./apiDataProvider";
import CustomLogo from "./components/CustomLogo";
import { notificationProvider } from "./notificationProvider";
import { CommunitiesList, CommunityCreate } from "./pages/communities";
import { CommunityEdit } from "./pages/communities/edit";
import { CommunityRolesList } from "./pages/communities/roles/list";
import { CommunityUsersList } from "./pages/communities/users/list";
import { UserEdit, UserFind, UserShow } from "./pages/users";
import { UserCreate } from "./pages/users/create";
import { UsersList } from "./pages/users/list";

export const apiUrl =
  process.env.REACT_APP_API_URL || "https://test.api.colkie.com/core/v0";

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

// Create an axios instance
const apiClient = axios.create({
  baseURL: apiUrl,
});

const App: React.FC = () => {
  const authProvider: AuthBindings = {
    login: async ({ gsmNumber, code }) => {
      try {
        const response: any = await apiClient.post(
          "/auth/validate-otp",
          {
            phone: gsmNumber,
            otp: code,
          },
          {
            headers: {
              "Device-Id": "backoffice-" + gsmNumber,
            },
          }
        );
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem("gsmNumber", gsmNumber);

        return {
          success: true,
          redirectTo: "/",
        };
      } catch (error: any) {
        return {
          success: false,
          error: new Error(error.response.data.message),
        };
      }
    },
    logout: async () => {
      localStorage.removeItem("gsmNumber");
      return {
        success: true,
        redirectTo: "/login",
      };
    },
    onError: async (error) => {
      return { error };
    },
    check: async () => {
      const jwtData = parseJwt(localStorage.getItem("accessToken") || "");
      if (
        jwtData.role &&
        jwtData.role === "admin" &&
        localStorage.getItem("gsmNumber")
      ) {
        return {
          authenticated: true,
        };
      } else {
        localStorage.removeItem("gsmNumber");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        notification.error({
          message:
            "There was an error. Check if your user has admin permissions.",
          description: "Error",
        });

        return {
          authenticated: false,
          redirectTo: "/login",
        };
      }
    },
    getPermissions: async () => ["admin"],
  };

  return (
    <BrowserRouter>
      <ConfigProvider theme={RefineThemes.Blue}>
        <Refine
          dataProvider={apiDataProvider(apiUrl)}
          authProvider={authProvider}
          routerProvider={routerProvider}
          resources={[
            {
              name: "communities",
              list: "/communities",
              create: "/communities/create",
              edit: "/communities/edit/:id",
            },
            {
              name: "users",
              list: "/users/list",
              create: "/users/create",
              edit: "/users/edit/:id",
              show: "/users/show/:id",
              meta: {
                canDelete: true,
              },
            },
          ]}
          notificationProvider={notificationProvider}
          options={{
            syncWithLocation: true,
            warnWhenUnsavedChanges: true,
          }}
        >
          <Routes>
            <Route
              element={
                <Authenticated
                  fallback={<CatchAllNavigate to="/login" />}
                  v3LegacyAuthProviderCompatible
                >
                  <ThemedLayoutV2
                    Sider={() => (
                      <ThemedSiderV2
                        Title={() => <CustomLogo />}
                        render={({ items, logout }) => {
                          return (
                            <>
                              {items}
                              {logout}
                            </>
                          );
                        }}
                      />
                    )}
                  >
                    <Outlet />
                  </ThemedLayoutV2>
                </Authenticated>
              }
            >
              <Route
                index
                element={<NavigateToResource resource="communities" />}
              />
              <Route index element={<NavigateToResource resource="users" />} />

              <Route path="/communities">
                <Route index element={<CommunitiesList />} />
                <Route path="create" element={<CommunityCreate />} />
                <Route path="edit/:id" element={<CommunityEdit />} />
                <Route
                  path=":id/users/total/:total"
                  element={<CommunityUsersList />}
                />
                <Route path=":id/reports" element={<CommunityReportsList />} />
                <Route path=":id/posts" element={<CommunityPostsList />} />
                <Route path=":id/roles" element={<CommunityRolesList />} />
                <Route path=":id/streaks" element={<CommunityStreaks />} />
                <Route path=":id/sessions" element={<CommunitySessions />} />
                <Route
                  path=":id/sessions/terms-and-conditions"
                  element={<TermsAndConditionsSessionsList />}
                />
                <Route
                  path=":id/roles/users/:userId/moderator-config"
                  element={<CommunityUserModeratorConfigEdit />}
                />
              </Route>

              <Route path="/users">
                <Route index path="list" element={<UsersList />} />
                <Route path="create" element={<UserCreate />} />
                <Route path="find" element={<UserFind />} />
                <Route path="edit/:id" element={<UserEdit />} />
                <Route path="show/:id" element={<UserShow />} />
              </Route>
            </Route>

            <Route
              element={
                <Authenticated
                  fallback={<Outlet />}
                  v3LegacyAuthProviderCompatible
                >
                  <NavigateToResource resource="communities" />
                </Authenticated>
              }
            >
              <Route path="/login" element={<Login />} />
            </Route>

            <Route
              element={
                <Authenticated v3LegacyAuthProviderCompatible>
                  <ThemedLayoutV2>
                    <Sider
                      render={({ items, logout }) => {
                        return (
                          <>
                            <div>My Custom Element</div>
                            {items}
                            {logout}
                          </>
                        );
                      }}
                    />
                    <Outlet />
                  </ThemedLayoutV2>
                </Authenticated>
              }
            >
              <Route path="*" element={<ErrorComponent />} />
            </Route>
          </Routes>
          <UnsavedChangesNotifier />
          <DocumentTitleHandler />
        </Refine>
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default App;
