import { apiUrl } from "App";
import axios from "axios";

export interface StreaksInsights {
  totalStreaksCount: number;
  enrolledUsersCount: number;
}

export async function getCommunityStreaksInsightsUseCase(
  id: string
): Promise<StreaksInsights> {
  const request = await axios.get(
    `${apiUrl}/communities/${id}/streaks/insights`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );

  return request.data;
}
