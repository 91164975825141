import { ArrowLeftOutlined } from "@ant-design/icons";
import { Show } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";

import { Breadcrumb, Transfer, Typography, notification } from "antd";
import { CommunityUserModeratorConfigDTO } from "interfaces";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isoCodeToCountry } from "services/countries/countryNames";
import { addCommunityUserModeratorConfigCountriesUseCase } from "usecases/community/addCommunityUserModeratorConfigCountriesUseCase";
import { getCommunityFollowersByCountryUseCase } from "usecases/community/getCommunityFollowersByCountryUseCase";
import { getCommunityUserModeratorConfigUseCase } from "usecases/community/getCommunityUserModeratorConfigUseCase";
import { removeCommunityUserModeratorConfigCountriesUseCase } from "usecases/community/removeCommunityUserModeratorConfigCountriesUseCase";

interface RecordType {
  key: string;
  title: string;
  description: string;
}
export const CommunityUserModeratorConfigEdit: React.FC<
  IResourceComponentsProps
> = () => {
  const navigate = useNavigate();
  const urlParams = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [countriesData, setCountriesData] = useState<RecordType[]>([]);
  const [moderatorConfig, setModeratorConfig] =
    useState<CommunityUserModeratorConfigDTO>();
  const [moderatorConfigCountries, setModeratorConfigCountries] =
    useState<string[]>();

  useEffect(() => {
    const fetchData = async () => {
      if (urlParams.id && urlParams.userId && !moderatorConfig) {
        try {
          const userModeratorConfigReq =
            await getCommunityUserModeratorConfigUseCase(
              urlParams.id,
              urlParams.userId
            );
          setModeratorConfig(userModeratorConfigReq.data);
        } catch (error: any) {
          notification.error({
            message: "Unable to get user's moderator config",
          });
          navigate(`/communities/${urlParams.id}/roles`);
        }
      }
    };

    fetchData();
  }, [urlParams.id, urlParams.userId]);

  useEffect(() => {
    const fetchData = async () => {
      if (urlParams.id) {
        const countriesReq = await getCommunityFollowersByCountryUseCase(
          urlParams.id
        );
        const countriesKeys = countriesReq.data.map(
          (country: { isoCode: string; followersCount: number }) => {
            const flag = isoToCountryFlag(country.isoCode);
            return {
              key: country.isoCode,
              title: `${flag} ${isoCodeToCountry[country.isoCode]}`,
              description: country.followersCount,
            };
          }
        );
        setCountriesData(countriesKeys);
      }
    };

    fetchData();
  }, [urlParams.id]);

  useEffect(() => {
    if (moderatorConfig) {
      setModeratorConfigCountries(moderatorConfig.countries);
    }
  }, [moderatorConfig]);

  useEffect(() => {
    if (moderatorConfig && countriesData.length > 0 && loading) {
      setLoading(false);
    }
  }, [moderatorConfig]);

  const filterOption = (inputValue: string, option: RecordType) =>
    option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;

  const handleChange = async (
    newTargetKeys: string[],
    direction: any,
    moveKeys: any
  ) => {
    if (direction === "right" && urlParams.id && urlParams.userId) {
      try {
        await addCommunityUserModeratorConfigCountriesUseCase(
          urlParams.id,
          urlParams.userId,
          moveKeys
        );
        setModeratorConfigCountries(newTargetKeys);
      } catch (error: any) {
        notification.error({
          message: "Unable to add countries to user's moderator config",
        });
      }
    }
    if (direction === "left" && urlParams.id && urlParams.userId) {
      try {
        await removeCommunityUserModeratorConfigCountriesUseCase(
          urlParams.id,
          urlParams.userId,
          moveKeys
        );
        setModeratorConfigCountries(newTargetKeys);
      } catch (error: any) {
        notification.error({
          message: "Unable to remove countries from user's moderator config",
        });
      }
    }
  };

  const isoToCountryFlag = (isoCode: string) => {
    const offset = 127397;
    return Array.from(isoCode)
      .map((char) => String.fromCodePoint(char.charCodeAt(0) + offset))
      .join("");
  };

  const { Title } = Typography;
  return (
    <Show
      breadcrumb={
        <div style={{ display: "flex", columnGap: "10px" }}>
          <ArrowLeftOutlined
            style={{ color: "gray", cursor: "pointer" }}
            onClick={() => {
              navigate(`/communities/${urlParams.id}/roles`);
            }}
          />
          <Breadcrumb
            items={[
              {
                title: "Communities",
                href: "/communities",
              },
              {
                title: "Roles",
                href: `/communities/${urlParams.id}/roles`,
              },
            ]}
          />
        </div>
      }
      headerProps={{ title: "Update user's moderator config" }}
      canDelete={false}
      headerButtons={<></>}
      isLoading={loading}
    >
      <Title level={4}>Countries</Title>
      <Transfer
        dataSource={countriesData}
        operations={["+", "-"]}
        showSearch
        filterOption={filterOption}
        targetKeys={moderatorConfigCountries}
        onChange={handleChange}
        render={(item) => `${item.title} (${item.description})`}
        listStyle={{
          width: "100%",
          minWidth: 120,
          height: 290,
          minHeight: 200,
        }}
      />
    </Show>
  );
};
