import axios from "axios";
import { apiUrl } from "../../App";
import { CommunityCreateDTO } from "../../interfaces";
import { getUserByUsernameUseCase } from "../../usecases/user/getUserByUsernameUseCase";

export async function createCommunity(variables: CommunityCreateDTO) {
  const user = await getUserByUsernameUseCase(variables.ownerUsername);

  return await axios.post(
    `${apiUrl}/admin/communities`,
    [
      {
        ...variables,
        image: undefined,
        ownerUsername: undefined,
        ownerId: user.data.id,
      },
    ],
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}
