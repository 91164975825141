import axios from "axios";
import { getCommunityPostsListUseCase } from "usecases/community/getCommunityPostsListUseCase";
import { getCommunityReportsListUseCase } from "usecases/community/getCommunityReportsListUseCase";
import { getcommunitySessionsTermsAndConditionsListUseCase } from "usecases/community/getCommunityTermsAndConditionsSessionsListUseCase";
import { updateUserUsernameUseCase } from "usecases/user/updateUserUsernameUseCase";
import { apiUrl } from "./App";
import { createCommunityUseCase } from "./usecases/community/createCommunityUseCase";
import { getCommunityUseCase } from "./usecases/community/getCommunityUseCase";
import { getListCommunityUseCase } from "./usecases/community/getListCommunityUseCase";
import { getCommunityRolesListUseCase } from "./usecases/community/role/getCommunityRolesListUseCase";
import { getCommunityUsersListUseCase } from "./usecases/community/user/getCommunityUsersListUseCase";
import { createUserUseCase } from "./usecases/user/createUserUseCase";
import { deleteUserUseCase } from "./usecases/user/deleteUserUseCase";
import { getUserByUsernameUseCase } from "./usecases/user/getUserByUsernameUseCase";

enum ResourceType {
  COMMUNITY = "communities",
  USER = "users",
  COMMUNITY_USER = "communityUsers",
  COMMUNITY_ROLE = "communityRoles",
  COMMUNITY_REPORTS = "communityReports",
  COMMUNITY_POSTS = "communityPosts",
  COMMUNITY_SESSIONS_TERMS_AND_CONDITIONS = "communitySessionsTermsAndConditions",
}

const apiDataProvider: (apiUrl: string) => {
  getApiUrl: () => string;
  deleteOne: ({ resource, id }: any) => Promise<{ data: any }>;
  getList: ({ resource, meta }: any) => Promise<any>;
  getOne: ({ resource, id }: any) => Promise<any>;
  create: ({ resource, variables }: any) => Promise<{ data: any }>;
  update: ({ resource, id, variables }: any) => Promise<{ data: any }>;
} = (apiUrl: string) => {
  return {
    create: async ({
      resource,
      variables,
    }: {
      resource: ResourceType;
      variables: any;
    }) => {
      if (resource === ResourceType.COMMUNITY) {
        return await createCommunityUseCase(variables);
      }
      if (resource === ResourceType.USER) {
        return await createUserUseCase(variables);
      }
      throw new Error(`Unsupported resource type: ${resource}`);
    },
    deleteOne: async ({
      resource,
      id,
    }: {
      resource: ResourceType;
      id: string;
    }) => {
      if (resource === ResourceType.USER) {
        return await deleteUserUseCase(id);
      }
      throw new Error(`Unsupported resource type: ${resource}`);
    },
    update: async ({
      resource,
      id,
      variables,
    }: {
      resource: ResourceType;
      id: string;
      variables: any;
    }) => {
      if (resource === ResourceType.USER) {
        return await updateUserUsernameUseCase(id, variables);
      } else {
        throw new Error(`Unsupported resource type: ${resource}`);
      }
    },
    getOne: async ({
      resource,
      id,
    }: {
      resource: ResourceType;
      id: string;
    }) => {
      if (resource === ResourceType.COMMUNITY) {
        return await getCommunityUseCase(id);
      }
      if (resource === ResourceType.USER) {
        return await getUserByUsernameUseCase(id);
      }
      throw new Error(
        `getOne method is not implemented for resource: ${resource}`
      );
    },
    getList: async ({
      resource,
      meta,
    }: {
      resource: ResourceType;
      meta: any;
    }) => {
      if (resource === ResourceType.COMMUNITY) {
        return await getListCommunityUseCase();
      }
      if (resource === ResourceType.COMMUNITY_USER) {
        return await getCommunityUsersListUseCase(
          meta.id,
          meta.prev,
          meta.next,
          meta.sortBy,
          meta.filterByCountry
        );
      }
      if (resource === ResourceType.COMMUNITY_ROLE) {
        return await getCommunityRolesListUseCase(meta.id);
      }
      if (resource === ResourceType.COMMUNITY_REPORTS) {
        return await getCommunityReportsListUseCase(
          meta.id,
          meta.prev,
          meta.next
        );
      }
      if (resource === ResourceType.COMMUNITY_POSTS) {
        return await getCommunityPostsListUseCase(
          meta.id,
          meta.prev,
          meta.next
        );
      }
      if (resource === ResourceType.COMMUNITY_SESSIONS_TERMS_AND_CONDITIONS) {
        return await getcommunitySessionsTermsAndConditionsListUseCase(
          meta.id,
          meta.prev,
          meta.next
        );
      }
      throw new Error(
        `getList method is not implemented for resource: ${resource}`
      );
    },
    getApiUrl: () => apiUrl,
  };
};

// Intercept response to handle token expiration
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url !== apiUrl + "/auth/refresh-token"
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = false;

        try {
          const refreshResponse: any = await axios.post(
            apiUrl + "/auth/refresh-token",
            {},
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("refreshToken"),
              },
            }
          );

          localStorage.setItem("accessToken", refreshResponse.data.accessToken);
          localStorage.setItem(
            "refreshToken",
            refreshResponse.data.refreshToken
          );

          processQueue(null, refreshResponse.data.accessToken);

          originalRequest.headers["Authorization"] =
            "Bearer " + refreshResponse.data.accessToken;

          return await axios.request(originalRequest);
        } catch (refreshError: any) {
          processQueue(refreshError, null);
          if (refreshError?.response?.status === 401) {
            logoutUser();
          }
        } finally {
          isRefreshing = false;
        }
      }

      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      }).then((token) => {
        originalRequest.headers["Authorization"] = "Bearer " + token;

        return axios.request(originalRequest);
      });
    } else if (originalRequest.url === apiUrl + "/auth/refresh-token") {
      logoutUser();
    }

    return Promise.reject(error);
  }
);

function logoutUser() {
  localStorage.removeItem("gsmNumber");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  window.location.href = "/login";
}

export default apiDataProvider;
