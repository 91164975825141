import { ShowButton } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import {
  Form,
  Input,
  Layout,
  Radio,
  RadioChangeEvent,
  Typography,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PhoneValidator } from "services/user/PhoneValidator";
import { getUserByPhoneUseCase } from "usecases/user/getUserByPhoneUseCase";
import { UsernameValidator } from "../../services/user/UsernameValidator";

const { Title } = Typography;

export const UserFind: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [recordItemId, setRecordItemId] = useState<string | undefined>(
    undefined
  );
  const [inputValue, setInputValue] = useState<string>("");
  const [findValue, setFindValue] = useState("username");

  const onChange = (e: RadioChangeEvent) => {
    setFindValue(e.target.value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const handleFormSubmit = async () => {
    if (findValue === "username") {
      setRecordItemId(inputValue);
    }

    if (findValue === "phone") {
      try {
        const user = await getUserByPhoneUseCase(inputValue);
        setRecordItemId(user?.data?.username);
      } catch (error: any) {
        if (error.response?.status === 404) {
          notification.error({
            message: "User not found",
            description: "Unable to find user with this phone number.",
          });
        } else {
          notification.error({
            message: error?.response?.data?.message,
            description: error?.response?.status,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (recordItemId) {
      navigate(`/users/show/${recordItemId}`);
      setRecordItemId(undefined);
    }
  }, [recordItemId]);

  return (
    <Layout>
      <Title level={5}>Find:</Title>
      <Radio.Group onChange={onChange} value={findValue}>
        <Radio value="username">by username</Radio>
        <Radio value="phone">by phone</Radio>
      </Radio.Group>
      <Form layout="vertical" form={form}>
        {findValue === "username" && (
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                validator: async (_, value: string) => {
                  const validator = new UsernameValidator();
                  if (validator.validate(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(validator.errorMessage()));
                },
              },
            ]}
          >
            <Input onChange={handleInputChange} />
          </Form.Item>
        )}
        {findValue === "phone" && (
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                validator: async (_, value: string) => {
                  const validator = new PhoneValidator();
                  if (validator.validate(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(validator.errorMessage()));
                },
              },
            ]}
          >
            <Input onChange={handleInputChange} />
          </Form.Item>
        )}
      </Form>
      <ShowButton
        size="small"
        onClick={() => {
          handleFormSubmit();
        }}
      />
    </Layout>
  );
};
