import {useShow, IResourceComponentsProps} from "@refinedev/core";
import {DeleteButton, EditButton, Show} from "@refinedev/antd";
import {Image, Tag, Typography} from "antd";
import { UserDTO } from "interfaces";
import React, {useState} from "react";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

function formatDate(date?: string) {
    if (!date) {
        return '-';
    }

    const userLocale = navigator.language || 'es-ES';

    const options: any = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    return new Intl.DateTimeFormat(userLocale, options).format(new Date(date));
}

export const UserShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<UserDTO>({
        queryOptions: {
            retry: 0,
        },
    });
    const { data, isLoading, error } = queryResult;

    const navigate = useNavigate();

    if (error) {
        navigate("/users/find");
    }

    const record = data?.data;

    const [isDeleted, setIsDeleted] = useState(Boolean(record?.deletedAt));

    return (
        <Show isLoading={isLoading} recordItemId={record?.id} headerButtons={
            record?.deletedAt ? <></> : <>
                <EditButton />
                <DeleteButton
                    recordItemId={record?.id}
                    onSuccess={() => {
                        setIsDeleted(true);
                    }}
                    confirmTitle="User will not be able to login again and their phone will be deleted. Are you sure?"
                />
            </>
        }>
            {isDeleted && <Tag color="red">User Deleted</Tag>}
            <Title level={1}>{record?.username || "-"}</Title>

            <Title level={5}>Id</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>Phone</Title>
            <Text>{record?.phone || "-"}</Text>

            <Title level={5}>Country Code</Title>
            <Text>{record?.countryCode}</Text>

            <Title level={5}>Verified</Title>
            <Text>{record?.verified.toString() || "false"}</Text>

            <Title level={5}>Role</Title>
            <Text>{record?.role || "-"}</Text>

            <Title level={5}>Onboarding Completed</Title>
            <Text>{record?.onboardingCompleted?.toString()}</Text>

            <Title level={5}>Date of deletion</Title>
            <Text>{formatDate(record?.deletedAt)}</Text>

            <Title level={5}>Image</Title>
            {record?.image?.webp?.thumbnail ?
                (<Image src={record?.image?.webp?.thumbnail} />) :
                (<Text>{record?.image || "No image"}</Text>)}
        </Show>
    );
};
