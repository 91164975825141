import axios from "axios";
import { CommunityPostRewardDTO } from "interfaces";
import { apiUrl } from "../../App";

export async function createCommunityPostRewardUseCase(
  communityPostRewardDTO: CommunityPostRewardDTO
) {
  const baseUrl = `${apiUrl}/admin/rankings/quality-content-creator-reward`;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  return await axios.post(baseUrl, { ...communityPostRewardDTO }, config);
}
