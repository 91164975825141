import React, {useState, useEffect, Dispatch} from 'react';
import { Form, Input, Select } from 'antd';
import axios from 'axios';
import {isoCodeToCountry} from "../services/countries/countryNames";
import * as process from "process";
import {PhoneOutlined} from "@ant-design/icons";

const { Option } = Select;

const isoToCountryFlag = (isoCode: string) => {
    const offset = 127397;
    return Array.from(isoCode).map(char => String.fromCodePoint(char.charCodeAt(0) + offset)).join('');
};

interface PhoneProps {
    setPhone: Dispatch<string>;
    setIsoCode: Dispatch<string>;
}

const PhoneForm: React.FC<PhoneProps> = ({ setPhone, setIsoCode }) => {
    const [phonePrefixes, setPhonePrefixes] = useState<{
        phone: string,
        isoCode: string;
        phonePrefix: string;
    }[]>([]);

    const apiUrl = process.env.REACT_APP_API_URL;


    useEffect(() => {
        const fetchPhonePrefixes = async () => {
            try {
                const response = await axios.get(`${apiUrl}/phone-prefixes`);
                setPhonePrefixes(response.data);
            } catch (error) {
                console.error("Error fetching phone prefixes:", error);
            }
        };

        fetchPhonePrefixes();
    }, []);

    const [selectedPrefix, setSelectedPrefix] = useState<string>('1');
    const [selectedCountry, setSelectedCountry] = useState<string>('US');
    const [phoneInput, setPhoneInput] = useState<string>('');

    const handleInputChange = (phoneInput: string, selectedPrefix: string) => {
        setPhone(`+${selectedPrefix}${phoneInput}`);
    };

    const handleIsoCodeChange = (value: string) => {
        const prefix = value.split(' ')[0].slice(1).replace('-', '');
        setSelectedPrefix(prefix);
        setPhone(`+${prefix}${phoneInput}`);
        setSelectedCountry(value.split(' ')[1]);

        setIsoCode(value.split(' ')[1]);
    };

    return (
        <>
            <Form.Item
                name="phone"
                label="Phone"
            >
                <Select
                    virtual={false}
                    value={`${isoToCountryFlag(selectedCountry)} +${selectedPrefix}`}
                    onChange={(value: string) => {
                        handleIsoCodeChange(value);
                    }}
                >
                    {phonePrefixes.map(country => (
                        <Option
                            value={`+${country.phonePrefix} ${country.isoCode} ${isoCodeToCountry[country.isoCode]}`}
                            key={`+${country.phonePrefix} ${country.isoCode}`}
                        >
                            {`+${country.phonePrefix} ${isoToCountryFlag(country.isoCode)} ${isoCodeToCountry[country.isoCode]}`}
                        </Option>
                    ))}
                </Select>
                <Input
                    prefix={<PhoneOutlined style={{ color: "#00000040" }} />}
                    maxLength={15}
                    placeholder=""
                    style={{marginTop: '0.5rem'}}
                    value={phoneInput}
                    onChange={(e) => {
                        setPhoneInput(e.target.value);
                        handleInputChange(e.target.value, selectedPrefix);
                    }}
                />
            </Form.Item>
        </>
    );
}

export default PhoneForm;
