import { IResourceComponentsProps } from "@refinedev/core";
import * as XLSX from "xlsx";

import { FilterDropdown, ImageField, List, useTable } from "@refinedev/antd";

import { Breadcrumb, Button, notification, Select, Table } from "antd";

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CommunityUserSortFields } from "usecases/community/user/getCommunityUsersListUseCase";
import { isoCodeToCountry } from "../../../services/countries/countryNames";
import { getAllCommunityUsersListUseCase } from "../../../usecases/community/user/getAllCommunityUsersListUseCase";

export const CommunityUsersList: React.FC<IResourceComponentsProps> = () => {
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const urlParams = useParams();

  const [paginatedUsers, setPaginatedUsers] = useState<any>();
  const [paginationNextID, setPaginationNextID] = useState<string | null>(null);
  const [paginationPrevID, setPaginationPrevID] = useState<string | null>(null);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [sorting, setSorting] = useState<string>("");
  const [filter, setFilter] = useState<string[]>([]);
  const [countryCodesSelection, setCountryCodesSelection] = useState<string[]>(
    []
  );

  const {
    tableProps,
    tableQueryResult: { data },
  } = useTable<any>({
    resource: "communityUsers",
    meta: {
      id: urlParams.id,
      prev: paginationPrevID,
      next: paginationNextID,
      sortBy: sorting,
      filterByCountry: filter,
    },
    pagination: { mode: "off" },
    queryOptions: {
      retry: 0,
    },
  });

  useEffect(() => {
    if (data?.data) {
      const users = data?.data;
      setPaginatedUsers(users);

      setPrevBtnEnabled(!!data?.cursor.prev);
      setNextBtnEnabled(!!data?.cursor.next);
      setTotalCount(data?.totalCount);
    }
  }, [data?.data]);

  async function handleDownloadExcel() {
    try {
      const usersResponse = await getAllCommunityUsersListUseCase(
        urlParams.id as string
      );

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(
        usersResponse.data.map((item: any) => {
          return {
            id: item.id,
            username: item.username,
            phone: item.phone,
            country: isoCodeToCountry[item.countryCode],
          };
        })
      );

      XLSX.utils.book_append_sheet(workbook, worksheet, "Members");

      XLSX.utils.sheet_add_aoa(worksheet, [
        ["ID", "Username", "Phone", "Country"],
      ]);

      XLSX.writeFile(workbook, "sesh-users-data.xlsx", { compression: true });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message,
        description: error?.response?.status,
      });
    }
  }

  function isReactElement(element: any): element is React.ReactElement {
    return (
      element !== null && typeof element === "object" && "$$typeof" in element
    );
  }

  return (
    <>
      <List
        breadcrumb={
          <div style={{ display: "flex", columnGap: "10px" }}>
            <ArrowLeftOutlined
              style={{ color: "gray", cursor: "pointer" }}
              onClick={() => {
                navigate("/communities");
              }}
            />
            <Breadcrumb
              items={[
                {
                  title: "Communities",
                  href: "/communities",
                },
                {
                  title: "Community Members Information",
                },
              ]}
            />
          </div>
        }
        headerButtons={() => {
          return (
            <Button
              onClick={handleDownloadExcel}
              style={{ height: "35px" }}
              className="ant-btn ant-btn-primary ant-btn-sm"
            >
              <DownloadOutlined /> Export data
            </Button>
          );
        }}
        title="Community Members Information"
      >
        <div style={{ display: "flex", gap: "0.4rem" }}>
          <h3># of members:</h3>
          <h3 style={{ color: "gray" }}>{totalCount ? totalCount : "N/A"}</h3>
        </div>
        <Table
          {...tableProps}
          dataSource={paginatedUsers}
          rowKey="id"
          key="table"
          ref={tableRef}
          pagination={false}
        >
          <Table.Column
            dataIndex="image"
            title="Image"
            align="center"
            render={(value) =>
              value?.webp?.thumbnail && (
                <ImageField
                  value={value.webp.thumbnail}
                  width={50}
                  preview={{ src: value?.webp?.main }}
                />
              )
            }
          />
          <Table.Column
            dataIndex="username"
            title="Username"
            sorter={true}
            onHeaderCell={(column) => {
              return {
                onClick: () => {
                  const title = column.title;
                  if (isReactElement(title)) {
                    title.props.title.includes("cancel") && setSorting("");
                    title.props.title.includes("ascending") &&
                      setSorting(CommunityUserSortFields.USERNAME_ASC);
                    title.props.title.includes("descending") &&
                      setSorting(CommunityUserSortFields.USERNAME_DESC);
                  }
                },
              };
            }}
          />
          <Table.Column dataIndex="id" title="ID" width={350} />
          <Table.Column dataIndex="phone" title="Phone number" />
          <Table.Column
            dataIndex="countryCode"
            title="Country"
            sorter={true}
            onHeaderCell={(column) => {
              return {
                onClick: () => {
                  const title = column.title;
                  if (isReactElement(title)) {
                    title.props.children.props.title.includes("cancel") &&
                      setSorting("");
                    title.props.children.props.title.includes("ascending") &&
                      setSorting(CommunityUserSortFields.COUNTRY_ASC);
                    title.props.children.props.title.includes("descending") &&
                      setSorting(CommunityUserSortFields.COUNTRY_DESC);
                  }
                },
              };
            }}
            filterDropdown={(props) => (
              <FilterDropdown
                {...props}
                confirm={() => setFilter(countryCodesSelection)}
                clearFilters={() => {
                  setFilter([]);
                  setCountryCodesSelection([]);
                }}
              >
                <Select
                  style={{ minWidth: 200 }}
                  mode="tags"
                  placeholder="Select Country"
                  options={Object.entries(isoCodeToCountry).map(
                    ([code, name]) => ({ label: name, value: name })
                  )}
                  onSelect={(value) => {
                    const isoCode = Object.keys(isoCodeToCountry).find(
                      (key) => isoCodeToCountry[key] === value
                    );
                    if (isoCode) {
                      setCountryCodesSelection([
                        ...countryCodesSelection,
                        isoCode,
                      ]);
                    }
                  }}
                  onDeselect={(value) => {
                    const isoCode = Object.keys(isoCodeToCountry).find(
                      (key) => isoCodeToCountry[key] === value
                    );
                    setCountryCodesSelection(
                      countryCodesSelection.filter((item) => item !== isoCode)
                    );
                  }}
                />
              </FilterDropdown>
            )}
          />
        </Table>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <Button
                icon={
                  <ArrowLeftOutlined
                    style={{
                      color: `${prevBtnEnabled}? "black" : "lightgrey"`,
                    }}
                  />
                }
                style={{ width: "3rem" }}
                onClick={() => {
                  setPaginationPrevID(data?.cursor.prev);
                  setPaginationNextID(null);
                }}
                disabled={!prevBtnEnabled}
              ></Button>
              <Button
                icon={
                  <ArrowRightOutlined
                    style={{
                      color: `${nextBtnEnabled}? "black" : "lightgrey"`,
                    }}
                  />
                }
                style={{ width: "3rem" }}
                onClick={() => {
                  setPaginationNextID(data?.cursor.next);
                  setPaginationPrevID(null);
                }}
                disabled={!nextBtnEnabled}
              ></Button>
            </div>
          </div>
        </div>
      </List>
    </>
  );
};
