import axios from "axios";
import { apiUrl } from "../../App";

export async function getCommunityFollowersByCountryUseCase(
  communityId: string
) {
  const url = `${apiUrl}/admin/communities/${communityId}/followers-by-country`;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  return axios.get(url, config);
}
