import React from "react";
import {IResourceComponentsProps} from "@refinedev/core";
import {Create, useForm} from "@refinedev/antd";
import {Button, Form, Input, Select} from "antd";
import {SaveOutlined} from "@ant-design/icons";
import {UserCreateDTO} from "../../interfaces";
import {Option} from "antd/lib/mentions";
import PhoneForm from "../../components/PhoneForm";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, form } = useForm<UserCreateDTO>();

    return (
        <Create saveButtonProps={{ style: { display: 'none' } }}>
            <Form
                {...formProps}
                layout="vertical"
                form={form}
            >
                <PhoneForm form={form}  />
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Role"
                    name="role"
                    initialValue={undefined}
                >
                    <Select placeholder="Select a role">
                        <Option value={undefined}>None</Option>
                        <Option value="admin">Admin</Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button icon={<SaveOutlined />} type="primary" size="large" htmlType="submit" style={{float: "right"}}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Create>
    );
};
