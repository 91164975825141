import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { Edit, useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import {
  Button,
  Checkbox,
  Form,
  Image,
  Input,
  Modal,
  Upload,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpotifyIdValidator } from "services/community/spotifyIdValidator";
import { updateCommunityOwner } from "services/community/updateCommunityOwner";
import { validate as uuidValidate } from "uuid";
import { CommunityUpdateDTO } from "../../interfaces";
import { updateCommunityUseCase } from "../../usecases/community/updateCommunityUseCase";

export const CommunityEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, form, formLoading } = useForm<CommunityUpdateDTO>({
    redirect: "list",
    warnWhenUnsavedChanges: false,
    queryOptions: {
      retry: 0,
    },
  });

  const params = useParams();
  form.setFieldsValue({ shareLink: "" });

  const [communityId, setCommunityId] = useState<string>("");

  const [isFileFormatValid, setIsFileFormatValid] = useState(true);
  const [isFileSizeValid, setIsFileSizeValid] = useState(true);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const [spotifyId, setSpotifyId] = useState<string>("");
  const [showSpotifyUrlWarning, setShowSpotifyUrlWarning] = useState(false);
  const [
    spotifyIdFeatureWarningDiscarded,
    setSpotifyIdFeatureWarningDiscarded,
  ] = useState(false);

  useEffect(() => {
    setSpotifyId(form.getFieldValue("spotifyId"));
  }, [form.getFieldValue("spotifyId")]);

  useEffect(() => {
    setCommunityId(params.id || "");
  }, [params, form]);

  const handleFormSubmit = async (values: any) => {
    if (!isFileFormatValid) {
      notification.error({
        message: "Invalid file type",
        description: "You can only upload .jpg file!",
      });
      return false;
    }
    if (!isFileSizeValid) {
      notification.error({
        message: "Invalid size",
        description: "Please upload a file no larger than 2MB.",
      });
      return false;
    }
    if (uploadedFile) {
      form.setFieldsValue({
        image: uploadedFile,
      });
    }

    await handleLinksSubmitValue();

    return true;
  };

  const handleLinksSubmitValue = async () => {
    let links: any = { ...form.getFieldValue("links") };

    for (const key in links) {
      if (links.hasOwnProperty(key)) {
        if (links[key] === "") {
          links[key] = null;
        }
      }
    }

    const allNull = Object.values(links).every((value) => value === null);
    if (allNull) {
      links = null;
    }

    form.setFieldsValue({
      links: links,
    });
  };

  async function onFinish(values: any) {
    try {
      if (
        !(values?.image instanceof File) ||
        values?.image?.file?.status === "removed"
      ) {
        values.image = undefined;
      }

      if (values.ownerId !== formProps.initialValues?.ownerId) {
        const isOwnerIdValid = uuidValidate(values.ownerId);
        if (!isOwnerIdValid) {
          notification.error({
            message: "Invalid owner ID",
            description: "Please add a valid uuid for the owner ID",
          });
          return;
        }

        await updateCommunityOwner(communityId, values.ownerId);
      }

      await updateCommunityUseCase(communityId, values);
      notification.success({
        message: "Community updated successfully",
      });
      setTimeout(() => {
        window.location.href = "/communities";
      }, 1000);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message,
        description: error?.response?.status,
      });
    }
  }

  return (
    <Edit
      saveButtonProps={{ style: { display: "none" } }}
      canDelete={false}
      headerButtons={<></>}
    >
      <Form
        form={form}
        {...formProps}
        layout="vertical"
        onFinish={onFinish}
        onSubmitCapture={handleFormSubmit}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Owner"
          name="ownerId"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div
          style={{
            marginTop: "5px",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>Image</span>
          {!uploadedFile && formProps.initialValues?.image?.webp?.main && (
            <Image
              src={formProps.initialValues?.image?.webp?.main}
              width={200}
              style={{ marginTop: "10px" }}
            />
          )}
        </div>
        <Form.Item name="image">
          <Upload
            name="image"
            listType="picture"
            accept=".jpg"
            maxCount={1}
            beforeUpload={(file) => {
              if (file.type !== "image/jpeg" || !file.name.endsWith(".jpg")) {
                setIsFileFormatValid(false);
                return true; // Unexpectedly, this will show file in red color as an error
              }

              if (file.size > 2 * 1024 * 1024) {
                setIsFileSizeValid(false);
                return true; // Unexpectedly, this will show file in red color as an error
              }

              setUploadedFile(file);

              setIsFileSizeValid(true);
              setIsFileFormatValid(true);
              return false; // Unexpectedly, this will show file in gray color as a success
            }}
            onRemove={() => {
              setUploadedFile(null);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        <div
          style={{
            marginTop: "-10px",
            marginBottom: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: "12px" }}>
            Only <b>.jpg</b> files are permitted for the image. (.jpeg, .png,
            .webp and any other extension <b>are NOT allowed</b>)
          </span>
          <span style={{ fontSize: "12px" }}>
            Maximum file size is <b>2MB</b>.
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#f9fbfc",
            padding: "1rem 1rem 0",
            marginBottom: "1rem",
          }}
        >
          <Form.Item
            label="Hidden"
            name="hidden"
            valuePropName="checked"
            rules={[
              {
                required: false,
                type: "boolean",
              },
            ]}
          >
            <Checkbox
              title={"Hidden"}
              name="hidden"
              onChange={(event) => {
                if (
                  !event.target.checked &&
                  !form.getFieldValue("spotifyId") &&
                  !spotifyIdFeatureWarningDiscarded
                ) {
                  Modal.warning({
                    title: "Warning",
                    content:
                      "Community will be accesible for users and, without a Spotify ID, it will not have the streaks feature enabled",
                    onOk() {
                      setSpotifyIdFeatureWarningDiscarded(true);
                    },
                  });
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Searchable"
            name="searchable"
            valuePropName="checked"
            rules={[
              {
                required: false,
                type: "boolean",
              },
            ]}
          >
            <Checkbox
              title={"Searchable"}
              name="searchable"
              onChange={(event) => {
                if (
                  event.target.checked &&
                  !form.getFieldValue("spotifyId") &&
                  !spotifyIdFeatureWarningDiscarded
                ) {
                  Modal.warning({
                    title: "Warning",
                    content:
                      "Community will be accesible for users and, without a Spotify ID, it will not have the streaks feature enabled",
                    onOk() {
                      setSpotifyIdFeatureWarningDiscarded(true);
                    },
                  });
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Verified"
            name="verified"
            valuePropName="checked"
            rules={[
              {
                required: false,
              },
            ]}
            initialValue={true}
          >
            <Checkbox
              title={"Verified"}
              name="verified"
              defaultChecked={true}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="Spotify ID"
          name="spotifyId"
          rules={[
            {
              required: false,
              validator: async (_, value: string) => {
                const validator = new SpotifyIdValidator();
                if (validator.validate(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(validator.errorMessage()));
              },
            },
          ]}
          style={{ marginBottom: showSpotifyUrlWarning ? "0" : "3rem" }}
        >
          <Input
            onChange={(e) => {
              setShowSpotifyUrlWarning(true);
              setSpotifyId(e.target.value);
            }}
          />
        </Form.Item>
        {showSpotifyUrlWarning && (
          <p style={{ fontSize: "12px", marginBottom: "3rem" }}>
            before saving changes make sure the profile url is correct{" "}
            <a
              href={`https://open.spotify.com/artist/${
                spotifyId || form.getFieldValue("spotifyId")
              }`}
              target="_blank"
              rel="noreferrer"
            >{`https://open.spotify.com/artist/${
              spotifyId || form.getFieldValue("spotifyId")
            }`}</a>
          </p>
        )}
        <h4
          style={{
            textAlign: "center",
          }}
        >
          Community Links
        </h4>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Item
            label="Tickets"
            name={["links", "events"]}
            rules={[
              {
                required: false,
                type: "url",
              },
            ]}
          >
            <Input placeholder="https://www.joinsesh.app/artist/timo/events" />
          </Form.Item>
        </div>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Item
            label="Merch"
            name={["links", "merch"]}
            rules={[
              {
                required: false,
                type: "url",
              },
            ]}
          >
            <Input placeholder="https://www.joinsesh.app/artist/timo/merch" />
          </Form.Item>
        </div>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Item
            label="LinkTree"
            name={["links", "linkTree"]}
            rules={[
              {
                required: false,
                type: "url",
              },
            ]}
          >
            <Input placeholder="https://linktr.ee/timo" />
          </Form.Item>
        </div>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Item
            label="Management App"
            name={["links", "managementApp"]}
            rules={[
              {
                required: false,
                type: "url",
              },
            ]}
          >
            <Input placeholder="https://artists.joinsesh.app/timo" />
          </Form.Item>
        </div>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>Share link name</span>
          <span style={{ marginTop: "5px", fontSize: "12px" }}>
            Current value is{" "}
            <b>
              {formProps.initialValues?.shareLink
                ? formProps.initialValues?.shareLink
                : "empty"}
            </b>
          </span>
        </div>
        <Form.Item
          name="shareLink"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder="thechange" />
        </Form.Item>
        <div
          style={{
            marginTop: "-10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: "12px" }}>
            This name should match with the url´s ending name of the community´s
            dynamic link in Firebase and the artist web page
            (https://joinsesh.app/artist/<b>thechange</b>)
          </span>
        </div>
        <Form.Item>
          <Button
            icon={<SaveOutlined />}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ float: "right" }}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Edit>
  );
};
