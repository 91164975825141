import React, { useState } from "react";
import { useLogin } from "@refinedev/core";

import { NumberOutlined } from "@ant-design/icons";
import {
    Row,
    Col,
    Layout as AntdLayout,
    Card,
    Form,
    Input,
    Button, notification,
} from "antd";
import axios from "axios";
import {apiUrl} from "../../App";
import LoginPhoneForm from "../../components/LoginPhoneForm";
export interface ILoginForm {
    gsmNumber: string;
    isoCode?: string;
    code: string;
}

export const Login: React.FC = () => {
    const [phone, setPhone] = useState<string>("");
    const [isoCode, setIsoCode] = useState<string>("US");
    const [current, setCurrent] = useState<"phone" | "code">("phone");
    const [loading, setLoading] = useState<boolean>(false);

    const { mutate: login, isLoading } = useLogin<ILoginForm>();

    const onGsmFormSubmit = async (values: ILoginForm) => {
        setLoading(true);
        try {
            await axios.post(apiUrl + "/auth/request-otp", {
                phone,
                isoCode,
            }, {
                headers: {
                    "Device-Id": "sesh-for-artists-" + phone,
                }
            });
            setCurrent("code");
        } catch (error: any) {
            setLoading(false);
            notification.error({
                message: error?.response?.status !== 429 ? error?.response?.data?.message : "Too many requests",
                description: error?.response?.status + " - Keep in mind if you try to login multiple times, you will be blocked for a while.",
            });
        }
    };

    const onCodeFormSubmit = async (values: Pick<ILoginForm, "code">) => {
        login({ gsmNumber: phone, code: values.code as string });
    };

    const renderGSMForm = () => (
        <>
            <Form layout="vertical" requiredMark={false} onFinish={onGsmFormSubmit}>
                <LoginPhoneForm setPhone={setPhone} setIsoCode={setIsoCode} />
                <Form.Item noStyle>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={loading}
                        block
                    >
                        Send
                    </Button>
                </Form.Item>
            </Form>
        </>
    );

    const renderCodeForm = () => (
        <Form
            layout="vertical"
            requiredMark={false}
            onFinish={onCodeFormSubmit}
        >
            <Form.Item
                name="code"
                label="OTP"
                rules={[
                    {
                        required: true,
                        message: "Code is required",
                    },
                ]}
            >
                <Input
                    maxLength={6}
                    prefix={<NumberOutlined style={{ color: "#00000040" }} />}
                />
            </Form.Item>
            <Form.Item noStyle>
                <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    block
                    loading={isLoading}
                >
                    Login
                </Button>
            </Form.Item>
        </Form>
    );

    return (
        <AntdLayout
            style={{
                background: `radial-gradient(50% 50% at 50% 50%, #000000 0%, #222222 100%)`,
                backgroundSize: "cover",
            }}
        >
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "28px",
                        }}
                    >
                        <img src="./sesh.svg" alt="Sesh" width={250} />
                    </div>

                    <Card
                        style={{
                            maxWidth: "400px",
                            margin: "auto",
                            borderRadius: "10px",
                        }}
                    >
                        {current === "phone"
                            ? renderGSMForm()
                            : renderCodeForm()}
                    </Card>
                </Col>
            </Row>
        </AntdLayout>
    );
};
