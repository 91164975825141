import axios from "axios";
import { apiUrl } from "../../App";

export async function updateUserVerifiedUseCase(id: string, variables: any) {
  return await axios.patch(`${apiUrl}/admin/users/${id}/verify`, variables, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  });
}
