import axios from "axios";
import {apiUrl} from "../../App";
import {CommunityUpdateDTO} from "../../interfaces";

export async function updateCommunity(id: string, variables: CommunityUpdateDTO) {
    await axios.patch(
        `${apiUrl}/admin/communities/${id}`, {...variables, image: undefined, shareLink: undefined},
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
        });
}
