import { IResourceComponentsProps } from "@refinedev/core";

import {
  BooleanField,
  EditButton,
  ImageField,
  List,
  useTable,
} from "@refinedev/antd";

import { Button, Popover, Table } from "antd";

import {
  CheckCircleFilled,
  CloseCircleFilled,
  EyeFilled,
  EyeInvisibleFilled,
  FileExclamationOutlined,
  FireOutlined,
  LinkOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ActionButton from "antd/es/_util/ActionButton";
import { CommunityDTO } from "interfaces";
import { useEffect, useState } from "react";

export const CommunitiesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<CommunityDTO>({
    pagination: { mode: "off" },
    queryOptions: {
      retry: 0,
    },
  });

  const [communities, setCommunities] = useState<CommunityDTO[]>([]);

  useEffect(() => {
    if (!communities?.length && tableProps?.dataSource?.length) {
      setCommunities(tableProps.dataSource as CommunityDTO[]);
    }
  }, [tableProps]);

  return (
    <>
      <List title="Communities">
        <Table {...tableProps} dataSource={communities} rowKey="id">
          <Table.Column
            dataIndex="image"
            title="Image"
            align="center"
            render={(value) =>
              value?.webp?.thumbnail && (
                <ImageField
                  value={value.webp.thumbnail}
                  width={50}
                  preview={{ src: value?.webp?.main }}
                />
              )
            }
          />
          <Table.Column
            dataIndex="name"
            title="Name"
            filters={communities.map((community) => ({
              text: community.name,
              value: community.name,
            }))}
            filterSearch={true}
            onFilter={(value, record: any) => record.name.startsWith(value)}
          />
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column
            dataIndex="verified"
            title="Verified"
            align="center"
            render={(value) => (
              <BooleanField
                value={value === true}
                trueIcon={<CheckCircleFilled style={{ color: "blue" }} />}
                falseIcon={null}
                valueLabelTrue="Verified"
                valueLabelFalse="Not verified"
                color={value === true ? "blue" : ""}
              />
            )}
          />
          <Table.Column
            dataIndex="hidden"
            title="Visibility"
            align="center"
            render={(value) => (
              <BooleanField
                value={value === true}
                trueIcon={<EyeInvisibleFilled style={{ color: "red" }} />}
                falseIcon={<EyeFilled style={{ color: "black" }} />}
                valueLabelTrue="Hidden"
                valueLabelFalse="Visible"
              />
            )}
          />
          <Table.Column
            dataIndex="searchable"
            title="Searchable"
            align="center"
            render={(value) => (
              <BooleanField
                value={value === true}
                trueIcon={<CheckCircleFilled style={{ color: "blue" }} />}
                falseIcon={<CloseCircleFilled style={{ color: "red" }} />}
                valueLabelTrue="Searchable"
                valueLabelFalse="Unsearchable"
              />
            )}
          />
          <Table.Column
            dataIndex="followersCount"
            title="Followers"
            align="center"
          />
          <Table.Column
            dataIndex="shareLink"
            title="Share Link"
            render={(shareLink) => {
              if (shareLink) {
                return (
                  <Popover
                    content={shareLink}
                    title="Copied to clipboard"
                    trigger="click"
                  >
                    <Button
                      icon={<LinkOutlined />}
                      onClick={() => {
                        navigator.clipboard.writeText(shareLink);
                      }}
                    />
                  </Popover>
                );
              }
            }}
          />
          <Table.Column
            title="Edit"
            render={(record) => (
              <EditButton hideText={true} recordItemId={record.id} />
            )}
          />
          <Table.Column
            title="Users"
            render={(record) => (
              <ActionButton
                prefixCls={"ant-btn"}
                actionFn={() => {
                  window.open(
                    `/communities/${record.id}/users/total/${record.followersCount}`,
                    "_self"
                  );
                }}
              >
                <TeamOutlined style={{ color: "black" }} />
              </ActionButton>
            )}
          />
          <Table.Column
            title="Roles"
            render={(record) => (
              <ActionButton
                prefixCls={"ant-btn"}
                actionFn={() => {
                  window.open(`/communities/${record.id}/roles`, "_self");
                }}
              >
                <UserOutlined style={{ color: "black" }} />
              </ActionButton>
            )}
          />
          <Table.Column
            title="Reports"
            render={(record) => (
              <ActionButton
                prefixCls={"ant-btn"}
                actionFn={() => {
                  window.open(`/communities/${record.id}/reports`, "_self");
                }}
              >
                <FileExclamationOutlined style={{ color: "black" }} />
              </ActionButton>
            )}
          />
          <Table.Column
            title="Posts"
            render={(record) => (
              <ActionButton
                prefixCls={"ant-btn"}
                actionFn={() => {
                  window.open(`/communities/${record.id}/posts`, "_self");
                }}
              >
                <SolutionOutlined style={{ color: "black" }} />
              </ActionButton>
            )}
          />
          <Table.Column
            title="Streaks"
            render={(record) => (
              <ActionButton
                prefixCls={"ant-btn"}
                actionFn={() => {
                  window.open(`/communities/${record.id}/streaks`, "_self");
                }}
              >
                <FireOutlined style={{ color: "black" }} />
              </ActionButton>
            )}
          />
          <Table.Column
            title="Sessions"
            render={(record) => (
              <ActionButton
                prefixCls={"ant-btn"}
                actionFn={() => {
                  window.open(`/communities/${record.id}/sessions`, "_self");
                }}
              >
                <img src="/channel-on.svg" alt="Sessions" />
              </ActionButton>
            )}
          />
        </Table>
      </List>
    </>
  );
};
