import axios from "axios";
import {apiUrl} from "../../App";

export async function getListCommunityUseCase(direction = "next", cursor: string|null = null) {
    let query = "";
    if (direction === "next" && cursor) {
        query += `?next=${cursor}`;
    } else if (direction === "prev" && cursor) {
        query += `?prev=${cursor}`;
    }

    return await axios.get(`${apiUrl}/admin/communities${query}`, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
    });
}