import axios from "axios";
import queryString from "query-string";
import { apiUrl } from "../../../App";

export interface CursorPaginationQuery {
  cursor?: string | null;
}

export enum CommunityUserSortFields {
  USERNAME_ASC = "username_asc",
  USERNAME_DESC = "username_desc",
  COUNTRY_ASC = "countryCode_asc",
  COUNTRY_DESC = "countryCode_desc",
}

export enum CommunityUserFilterFields {
  COUNTRY_CODES = "countryCodes",
}
export interface UsersQueryParams {
  sortBy?: CommunityUserSortFields;
  filterByCountry?: string[];
  paginationQuery?: CursorPaginationQuery;
}

export async function getCommunityUsersListUseCase(
  communityId: string,
  prev: string,
  next: string,
  sortBy?: CommunityUserSortFields,
  filterByCountry?: string[]
) {
  let paginationQuery: CursorPaginationQuery = {};
  if (next) {
    paginationQuery.cursor = `next=${next}`;
  }
  if (prev) {
    paginationQuery.cursor = `prev=${prev}`;
  }

  const urlQuery = buildUsersQueryParams({
    paginationQuery,
    sortBy,
    filterByCountry,
  });

  const baseUrl = `${apiUrl}/admin/communities/${communityId}/users`;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  const mainResults = await axios.get(`${baseUrl}${urlQuery}`, config);
  const resultsData = [...mainResults.data];

  let nextId = null;
  let nextResults = null;

  let prevId = null;
  let prevResults = null;

  let totalCount = 0;

  if (resultsData.length) {
    nextId = mainResults.headers["next-cursor"];
    const urlQueryNext = buildUsersQueryParams({
      paginationQuery: { cursor: `next=${nextId}` },
      sortBy,
      filterByCountry,
    });
    nextResults = await axios.get(`${baseUrl}${urlQueryNext}`, config);

    prevId = mainResults.headers["prev-cursor"];
    const urlQueryPrev = buildUsersQueryParams({
      paginationQuery: { cursor: `prev=${prevId}` },
      sortBy,
      filterByCountry,
    });
    prevResults = await axios.get(`${baseUrl}${urlQueryPrev}`, config);

    totalCount = parseInt(mainResults.headers["total-count"]);
  }

  if (prev && resultsData.length) {
    resultsData.reverse();
  }

  return {
    data: resultsData || [],
    totalCount,
    cursor: {
      next: nextResults?.data?.length ? nextId : null,
      prev: prevResults?.data?.length ? prevId : null,
    },
  };
}

const buildUsersQueryParams = (queryParams?: UsersQueryParams) => {
  const queryParamsList = [];

  if (queryParams?.filterByCountry?.length) {
    queryParamsList.push(
      queryString.stringify(
        {
          [CommunityUserFilterFields.COUNTRY_CODES]:
            queryParams.filterByCountry,
        },
        { arrayFormat: "comma" }
      )
    );
  }
  if (queryParams?.sortBy) {
    queryParamsList.push(`sortBy=${queryParams.sortBy}`);
  }
  if (queryParams?.paginationQuery) {
    const { cursor } = queryParams.paginationQuery;
    if (cursor) {
      queryParamsList.push(queryParams.paginationQuery.cursor);
    }
  }

  const urlQuery = !!queryParamsList.length
    ? `?${queryParamsList.join("&")}`
    : "";

  return urlQuery;
};
