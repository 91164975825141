export class SpotifyIdValidator {
  private regex: RegExp = /^[0-9A-Za-z]+$/;
  validate(spotifyId: string): boolean {
    if (spotifyId) {
      return this.regex.test(spotifyId);
    }
    return true;
  }

  errorMessage(): string {
    return "spotify ID must only contain letters and numbers.";
  }
}
