import { SearchOutlined } from "@ant-design/icons";
import { CreateButton } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Button, Layout } from "antd";
import { useNavigate } from "react-router-dom";

export const UsersList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div>
        <h1>Users</h1>
        <CreateButton style={{ marginRight: "10px" }} />
        <Button
          type="default"
          icon={<SearchOutlined />}
          onClick={() => {
            navigate("/users/find");
          }}
        >
          Find
        </Button>
      </div>
    </Layout>
  );
};
