import axios from "axios";
import { apiUrl } from "../../../App";

export async function getAllCommunityUsersListUseCase(communityId: string) {
  return await axios.get(
    `${apiUrl}/admin/communities/${communityId}/users?all=true`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}
