import { IResourceComponentsProps } from "@refinedev/core";
import * as XLSX from "xlsx";

import { List, useTable } from "@refinedev/antd";

import {
  Breadcrumb,
  Button,
  Popover,
  Table,
  Typography,
  notification,
} from "antd";

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DownloadOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import ActionButton from "antd/es/_util/ActionButton";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCommunityTermsAndConditionsSessionUsersUseCase } from "usecases/community/getCommunityTermsAndConditionsSessionUsersUseCase";

export const TermsAndConditionsSessionsList: React.FC<
  IResourceComponentsProps
> = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [paginatedMessages, setPaginatedMessages] = useState<any>();
  const [paginationNextID, setPaginationNextID] = useState<string | null>(null);
  const [paginationPrevID, setPaginationPrevID] = useState<string | null>(null);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);

  const {
    tableProps,
    tableQueryResult: { data },
  } = useTable<any>({
    resource: "communitySessionsTermsAndConditions",
    pagination: { mode: "off" },
    meta: {
      id,
      prev: paginationPrevID,
      next: paginationNextID,
    },
    queryOptions: {
      retry: 0,
    },
  });

  useEffect(() => {
    if (data?.data) {
      const messages = data?.data;
      setPaginatedMessages(messages);

      setPrevBtnEnabled(!!data?.cursor.prev);
      setNextBtnEnabled(!!data?.cursor.next);
    }
  }, [data?.data]);

  function formatUnixTimestamp(timestamp: number) {
    const date = new Date(timestamp);
    return date.toLocaleString(undefined, {
      dateStyle: "short",
      timeZone: "UTC",
    });
  }

  const isoToCountryFlag = (isoCode: string) => {
    const offset = 127397;
    return Array.from(isoCode)
      .map((char) => String.fromCodePoint(char.charCodeAt(0) + offset))
      .join("");
  };

  async function downloadTermsAndConditionsUsers(sessionId: string) {
    try {
      const response = await getCommunityTermsAndConditionsSessionUsersUseCase(
        sessionId
      );

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(
        response.data.map((item: any) => {
          return {
            username: item.user.username,
            phone: item.user.phone,
            country: item.user.countryCode,
            acceptedAt: formatUnixTimestamp(item.acceptedAt),
          };
        })
      );

      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "accepted-terms-and-conditions"
      );

      XLSX.utils.sheet_add_aoa(worksheet, [
        ["Username", "Phone", "Country", "Accepted At"],
      ]);

      XLSX.writeFile(workbook, "sesh-terms-and-conditions-session-data.xlsx", {
        compression: true,
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message,
        description: error?.response?.status,
      });
    }
  }

  const { Title } = Typography;
  return (
    <>
      <Title level={3}>Terms and conditions sessions</Title>
      <List
        breadcrumb={
          <div style={{ display: "flex", columnGap: "10px" }}>
            <ArrowLeftOutlined
              style={{ color: "gray", cursor: "pointer" }}
              onClick={() => {
                navigate("/communities/sessions");
              }}
            />
            <Breadcrumb
              items={[
                {
                  title: "Communities",
                  href: `/communities`,
                },
                {
                  title: "Sessions",
                  href: `/communities/${id}/sessions`,
                },
                {
                  title: "Terms and conditions",
                },
              ]}
            />
          </div>
        }
      >
        <Table
          {...tableProps}
          dataSource={paginatedMessages}
          rowKey="id"
          key="table"
          pagination={false}
        >
          <Table.Column
            dataIndex="sentAt"
            title="Creation Date"
            render={(value) => formatUnixTimestamp(value)}
          />
          <Table.Column
            dataIndex="sessionId"
            title="Session ID"
            render={(value) => value}
          />
          <Table.Column
            dataIndex="owner"
            title="Owner"
            render={(value) => value.username}
          />
          <Table.Column
            dataIndex="text"
            title="Topic"
            render={(value) => value}
          />
          <Table.Column
            dataIndex="metadata"
            title="Country Segmentation"
            align="center"
            render={(value) => {
              if (value?.countryCodes?.length) {
                return value.countryCodes.map((countryCode: string) =>
                  isoToCountryFlag(value.country)
                );
              }
              return "all";
            }}
          />
          <Table.Column
            dataIndex="metadata"
            title=" T&C link"
            render={(value) => {
              if (value.termsAndConditionsUrl) {
                return (
                  <Popover
                    content={value.termsAndConditionsUrl}
                    title="Copied to clipboard"
                    trigger="click"
                  >
                    <Button
                      icon={<LinkOutlined />}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          value.termsAndConditionsUrl
                        );
                      }}
                    />
                  </Popover>
                );
              }
            }}
          />
          <Table.Column
            title="Download"
            render={(record) => {
              if (record.metadata?.secondsToExpire === 0) {
                return (
                  <ActionButton
                    prefixCls={"ant-btn"}
                    actionFn={() => {
                      downloadTermsAndConditionsUsers(record.sessionId);
                    }}
                  >
                    <DownloadOutlined style={{ color: "black" }} />
                  </ActionButton>
                );
              }
              return "Ongoing session...";
            }}
          />
        </Table>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "flex-end",
          }}
        >
          <div>
            <Button
              icon={
                <ArrowLeftOutlined
                  style={{
                    color: `${prevBtnEnabled}? "black" : "lightgrey"`,
                  }}
                />
              }
              style={{ width: "3rem" }}
              onClick={() => {
                setPaginationPrevID(data?.cursor.prev);
                setPaginationNextID(null);
              }}
              disabled={!prevBtnEnabled}
            ></Button>
            <Button
              icon={
                <ArrowRightOutlined
                  style={{
                    color: `${nextBtnEnabled}? "black" : "lightgrey"`,
                  }}
                />
              }
              style={{ width: "3rem" }}
              onClick={() => {
                setPaginationNextID(data?.cursor.next);
                setPaginationPrevID(null);
              }}
              disabled={!nextBtnEnabled}
            ></Button>
          </div>
        </div>
      </List>
    </>
  );
};
