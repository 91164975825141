import axios from "axios";
import { apiUrl } from "../../App";

export async function removeCommunityChannelMessageUseCase(
  channelId: string,
  messageId: string
) {
  const url = `${apiUrl}/admin/chat/channels/${channelId}/messages/${messageId}`;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  return axios.delete(url, config);
}
