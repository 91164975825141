import axios from "axios";
import { apiUrl } from "../../App";

export async function blockUserUseCase(id: string) {
  return await axios.post(`${apiUrl}/admin/users/${id}/block`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  });
}
