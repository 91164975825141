import { Form, Input, Select, notification } from "antd";
import axios from "axios";
import * as process from "process";
import React, { useEffect, useState } from "react";
import { isoCodeToCountry } from "../services/countries/countryNames";

const { Option } = Select;

const isoToCountryFlag = (isoCode: string) => {
  const offset = 127397;
  return Array.from(isoCode)
    .map((char) => String.fromCodePoint(char.charCodeAt(0) + offset))
    .join("");
};

interface PhoneProps {
  form: any;
}

const PhoneForm: React.FC<PhoneProps> = ({ form }) => {
  const [phonePrefixes, setPhonePrefixes] = useState<
    {
      phone: string;
      isoCode: string;
      phonePrefix: string;
    }[]
  >([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchPhonePrefixes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/phone-prefixes`);
        setPhonePrefixes(response.data);
      } catch (error) {
        notification.error({
          message: "Error",
          description: "An error occurred while fetching phone prefixes.",
        });
      }
    };

    fetchPhonePrefixes();
  }, []);

  const [selectedPrefix, setSelectedPrefix] = useState<string>("1");
  const [selectedCountry, setSelectedCountry] = useState<string>("US");
  const [phoneInput, setPhoneInput] = useState<string>("");

  useEffect(() => {
    form.setFieldsValue({
      phone: `+${selectedPrefix}${phoneInput}`,
      isoCode: selectedCountry,
    });
  }, [phoneInput, selectedPrefix, selectedCountry]);

  const handleIsoCodeChange = (value: string) => {
    const prefix = value.split(" ")[0].slice(1);
    setSelectedPrefix(prefix);
    setSelectedCountry(value.split(" ")[1]);
  };

  return (
    <>
      <Form.Item name="phone" label="Phone">
        <Input.Group compact>
          <Select
            style={{ width: "30%" }}
            virtual={false}
            value={`+${selectedPrefix} ${isoToCountryFlag(selectedCountry)}`}
            onChange={(value: string) => {
              handleIsoCodeChange(value);
            }}
          >
            {phonePrefixes.map((country) => (
              <Option
                value={`+${country.phonePrefix} ${country.isoCode} ${
                  isoCodeToCountry[country.isoCode]
                }`}
                key={`+${country.phonePrefix} ${country.isoCode}`}
              >
                {`+${country.phonePrefix} ${isoToCountryFlag(
                  country.isoCode
                )} ${isoCodeToCountry[country.isoCode]}`}
              </Option>
            ))}
          </Select>
          <Input
            style={{ width: "70%" }}
            value={phoneInput}
            onChange={(e) => {
              setPhoneInput(e.target.value);
            }}
          />
        </Input.Group>
      </Form.Item>
      <Form.Item name="isoCode" style={{ display: "none" }}>
        <Input />
      </Form.Item>
    </>
  );
};

export default PhoneForm;
