import axios from "axios";
import {apiUrl} from "../../App";

export async function updateCommunityShareLink(communityId: string, shareLinkName: string) {
    if (shareLinkName) {
        await axios.patch(`${apiUrl}/admin/communities/${communityId}/share-link`, {shareLinkName}, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
        });
    }
}
