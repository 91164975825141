import axios from "axios";
import { apiUrl } from "../../App";

export async function getCommunityTermsAndConditionsSessionUsersUseCase(
  sessionId: string
) {
  return await axios.get(
    `${apiUrl}/admin/chat/sessions/${sessionId}/terms-and-conditions`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}
