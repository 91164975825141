import axios from "axios";
import { apiUrl } from "../../App";

export async function removeCommunityUserModeratorConfigCountriesUseCase(
  communityId: string,
  userId: string,
  countries: string[]
) {
  const url = `${apiUrl}/admin/chat/channels/${communityId}/users/${userId}/moderator-config/countries`;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
    data: { countries },
  };

  return axios.delete(url, config);
}
