import {updateCommunityImage} from "../../services/community/updateCommunityImage";
import {createCommunity} from "../../services/community/createCommunity";
import {updateCommunityShareLink} from "../../services/community/updateCommunityShareLink";
import {CommunityCreateDTO} from "../../interfaces";

export async function createCommunityUseCase(variables: CommunityCreateDTO) {

    const communityResponse = await createCommunity(variables)

    if (communityResponse.data.errors.length) throw new Error(communityResponse.data.errors[0].message);

    if (communityResponse.data.successes.length) {
        if (variables.shareLink !== undefined) {
            await updateCommunityShareLink(communityResponse.data.successes[0].id, variables.shareLink);
        }

        if (variables.image !== undefined && variables.image !== null) {
            await updateCommunityImage(communityResponse.data.successes[0].id, variables.image);
        }
    }

    return { data: communityResponse.data };
}
