import axios from "axios";
import { apiUrl } from "../../App";

export async function getUserByPhoneUseCase(phone: string) {
  return await axios.get(`${apiUrl}/admin/users/phone/${phone}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  });
}
