import axios from "axios";
import { apiUrl } from "../../App";

export async function updateUserProfileImage(userId: string, image: File) {
  if (!image.name?.toLowerCase().endsWith(".jpg")) {
    throw new Error("Only .jpg files are allowed");
  }

  const imageUploadURLResponse = await axios.post(
    `${apiUrl}/admin/users/${userId}/profile-image`,
    {},
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );

  await axios.put(imageUploadURLResponse.data.url, image, {
    headers: {
      "Content-Type": "image/jpeg",
    },
  });
}
