import axios from "axios";
import { apiUrl } from "../../App";

export enum CommunityReportStatus {
  PENDING = "pending",
  SOLVED = "solved",
}

export async function updateCommunityReportStatusUseCase(
  reportId: string,
  status: CommunityReportStatus
) {
  const url = `${apiUrl}/admin/chat/reports/${reportId}/status`;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  return axios.put(url, { status }, config);
}
