import React from "react";
import { Link } from "react-router-dom";

const CustomLogo: React.FC = () => {
  return (
    <div
      style={{
        width: "200px",
        padding: "0px 16px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "64px",
        backgroundColor: "rgb(255, 255, 255)",
        borderBottom: "1px solid rgb(217, 217, 217)",
      }}
    >
      <Link to="/" style={{ display: "inline-block", textDecoration: "none" }}>
        <div
          className="ant-space css-dev-only-do-not-override-12upa3x ant-space-horizontal ant-space-align-center"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "inherit",
            gap: "8px",
          }}
        >
          <div className="ant-space-item">
            <div
              style={{
                height: "50px",
                width: "120px",
                color: "rgb(22, 119, 255)",
              }}
            >
              <img
                height="50"
                width="120"
                src="/sesh_dark.svg"
                alt="Sesh logo"
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CustomLogo;
