import { IResourceComponentsProps } from "@refinedev/core";

import { ImageField, List, useForm, useTable } from "@refinedev/antd";

import {
  Breadcrumb,
  Button,
  Form,
  InputNumber,
  Modal,
  Table,
  Typography,
  notification,
} from "antd";

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleFilled,
  TrophyOutlined,
} from "@ant-design/icons";
import { CommunityPostRewardDTO } from "interfaces";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import { createCommunityPostRewardUseCase } from "usecases/community/createCommunityPostRewardUseCase";
import { getCommunityFandomIdUseCase } from "usecases/community/getCommunityFandomId";

interface CommunityPostRewardModalData extends CommunityPostRewardDTO {
  username: string;
}

export const CommunityPostsList: React.FC<IResourceComponentsProps> = () => {
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const urlParams = useParams();

  const [fandomId, setFandomId] = useState<string | null>(null);

  const [paginatedPosts, setPaginatedPosts] = useState<any>();
  const [paginationNextID, setPaginationNextID] = useState<string | null>(null);
  const [paginationPrevID, setPaginationPrevID] = useState<string | null>(null);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);

  const [showRewardModal, setShowRewardModal] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalPostData, setModalPostData] =
    useState<CommunityPostRewardModalData>();

  useEffect(() => {
    const fetchData = async () => {
      if (urlParams.id && !fandomId) {
        const fandomIdRequest = await getCommunityFandomIdUseCase(urlParams.id);
        setFandomId(fandomIdRequest.data.id);
      }
    };

    fetchData();
  }, [urlParams.id]);

  const {
    tableProps,
    tableQueryResult: { data },
  } = useTable<any>({
    resource: "communityPosts",
    pagination: { mode: "off" },
    meta: {
      id: fandomId,
      prev: paginationPrevID,
      next: paginationNextID,
    },
    queryOptions: {
      retry: 0,
    },
  });

  useEffect(() => {
    if (data?.data) {
      const reports = data?.data;
      setPaginatedPosts(reports);

      setPrevBtnEnabled(!!data?.cursor.prev);
      setNextBtnEnabled(!!data?.cursor.next);
    }
  }, [data?.data]);

  const { formProps, form } = useForm<CommunityPostRewardDTO>({
    redirect: "list",
    warnWhenUnsavedChanges: false,
    queryOptions: {
      retry: 0,
    },
  });

  async function onFinish(values: any) {
    if (!modalPostData) {
      notification.error({
        message: "Post data unavailable",
        description: "Please refresh the page and try again.",
      });
      return;
    }

    try {
      setModalLoading(true);
      await createCommunityPostRewardUseCase({
        communityId: modalPostData.communityId,
        postId: modalPostData.postId,
        userId: modalPostData.userId,
        points: values.points,
      });
      notification.success({
        message: "Post rewarded successfully",
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message,
        description: error?.response?.status,
      });
    }
    setModalLoading(true);
    setShowRewardModal(false);
  }

  const handleRewardModalCancel = () => {
    setShowRewardModal(false);
  };

  function formatUnixTimestamp(timestamp: number) {
    const date = new Date(timestamp);
    return date.toLocaleString(undefined, {
      dateStyle: "short",
      timeStyle: "short",
      hourCycle: "h23",
      timeZone: "UTC",
    });
  }

  const isoToCountryFlag = (isoCode: string) => {
    const offset = 127397;
    return Array.from(isoCode)
      .map((char) => String.fromCodePoint(char.charCodeAt(0) + offset))
      .join("");
  };

  const { Title } = Typography;
  return (
    <>
      <Title level={3}>Community Posts</Title>
      <Modal
        title={`Reward post quality`}
        open={showRewardModal}
        confirmLoading={modalLoading}
        onCancel={handleRewardModalCancel}
        destroyOnClose={true}
        footer={null}
        style={{ maxWidth: 320 }}
      >
        <p>{`User: ${modalPostData?.username}`}</p>
        <Form
          form={form}
          {...formProps}
          preserve={false}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label="Points"
            name="points"
            initialValue={1}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ float: "right" }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <List
        breadcrumb={
          <div style={{ display: "flex", columnGap: "10px" }}>
            <ArrowLeftOutlined
              style={{ color: "gray", cursor: "pointer" }}
              onClick={() => {
                navigate("/communities");
              }}
            />
            <Breadcrumb
              items={[
                {
                  title: "Communities",
                  href: "/communities",
                },
                {
                  title: "Posts",
                },
              ]}
            />
          </div>
        }
      >
        <Table
          {...tableProps}
          dataSource={paginatedPosts}
          rowKey="id"
          key="table"
          ref={tableRef}
          pagination={false}
        >
          <Table.Column
            dataIndex="sentAt"
            title="Sent at"
            render={(value) => formatUnixTimestamp(value)}
            width={"10%"}
          />
          <Table.Column
            dataIndex="owner"
            title="Country"
            align="center"
            render={(value) => isoToCountryFlag(value.country)}
          />
          <Table.Column
            dataIndex="owner"
            title="Username"
            render={(value) => value.username}
          />
          <Table.Column
            dataIndex="owner"
            title="Role"
            render={(value) => value.role}
          />
          <Table.Column
            dataIndex="owner"
            title="Verified"
            render={(value) =>
              value.metadata?.verified ? (
                <CheckCircleFilled style={{ color: "blue" }} />
              ) : (
                ""
              )
            }
          />
          <Table.Column
            dataIndex="metadata"
            title="Interactions"
            align="center"
            render={(value) => value.sessionMessagesCount}
          />
          <Table.Column
            dataIndex="text"
            title="Text"
            render={(value) => value}
          />
          <Table.Column
            dataIndex="multimedia"
            title="Multimedia"
            align="center"
            width={"20%"}
            render={(value) => {
              switch (value?.kind) {
                case "image":
                  return (
                    <ImageField
                      value={value?.formats?.webp?.thumbnail}
                      width={150}
                      preview={{
                        src: value?.formats?.webp?.main,
                      }}
                    />
                  );
                case "video":
                  return (
                    <ReactPlayer
                      url={value?.formats?.hls?.main}
                      controls
                      width={150}
                      style={{ transform: "translateX(50%)" }}
                    />
                  );
                case "audio":
                  return (
                    <ReactPlayer
                      url={value?.formats?.hls?.main}
                      controls
                      width={"80%"}
                      height={35}
                    />
                  );
                default:
                  return null;
              }
            }}
          />
          <Table.Column
            title="Quality content reward"
            align="center"
            render={(record) => (
              <Button
                prefixCls={"ant-btn"}
                onClick={() => {
                  setShowRewardModal(true);
                  setModalPostData({
                    communityId: urlParams.id || "",
                    postId: record.id,
                    userId: record.owner.id,
                    points: 0,
                    username: record.owner.username,
                  });
                }}
              >
                <TrophyOutlined style={{ color: "black" }} />
              </Button>
            )}
          />
        </Table>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "flex-end",
          }}
        >
          <div>
            <Button
              icon={
                <ArrowLeftOutlined
                  style={{
                    color: `${prevBtnEnabled}? "black" : "lightgrey"`,
                  }}
                />
              }
              style={{ width: "3rem" }}
              onClick={() => {
                setPaginationPrevID(data?.cursor.prev);
                setPaginationNextID(null);
              }}
              disabled={!prevBtnEnabled}
            ></Button>
            <Button
              icon={
                <ArrowRightOutlined
                  style={{
                    color: `${nextBtnEnabled}? "black" : "lightgrey"`,
                  }}
                />
              }
              style={{ width: "3rem" }}
              onClick={() => {
                setPaginationNextID(data?.cursor.next);
                setPaginationPrevID(null);
              }}
              disabled={!nextBtnEnabled}
            ></Button>
          </div>
        </div>
      </List>
    </>
  );
};
