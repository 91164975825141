import { SnippetsOutlined } from "@ant-design/icons";
import { IResourceComponentsProps } from "@refinedev/core";
import { Card, Typography } from "antd";
import { FC } from "react";
import { useParams } from "react-router-dom";

export const CommunitySessions: FC<IResourceComponentsProps> = () => {
  const { id } = useParams();

  const { Title } = Typography;
  return (
    <>
      <Title level={3} style={{ marginBottom: "2rem" }}>
        Community Sessions
      </Title>
      <Card
        bordered={true}
        style={{ width: "15rem" }}
        hoverable
        onClick={() =>
          window.open(
            `/communities/${id}/sessions/terms-and-conditions`,
            "_self"
          )
        }
      >
        <SnippetsOutlined /> Terms & conditions
      </Card>
    </>
  );
};
