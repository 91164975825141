import { IResourceComponentsProps } from "@refinedev/core";

import { Edit, ImageField, List, useForm, useTable } from "@refinedev/antd";

import {
  Breadcrumb,
  Button,
  Form,
  Input,
  notification,
  Select,
  Table,
} from "antd";

import {
  ArrowLeftOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCommunityFandomIdUseCase } from "usecases/community/getCommunityFandomId";
import { UserUpdateDTO } from "../../../interfaces";
import {
  FollowerRole,
  FollowerRoleValidator,
} from "../../../services/community/follower/FollowerRoleValidator";
import { UsernameValidator } from "../../../services/user/UsernameValidator";
import { updateCommunityUserRoleUseCase } from "../../../usecases/community/follower/updateCommunityUserRoleUseCase";
import { getUserByUsernameUseCase } from "../../../usecases/user/getUserByUsernameUseCase";

export const CommunityRolesList: React.FC<IResourceComponentsProps> = () => {
  const [fandomId, setFandomId] = useState<string | null>(null);

  const { formProps } = useForm<UserUpdateDTO>({
    redirect: "show",
    warnWhenUnsavedChanges: false,
    queryOptions: {
      retry: 0,
    },
  });

  async function onFinish(values: any) {
    try {
      const userResponse = await getUserByUsernameUseCase(values.username);
      if (urlParams.id && userResponse?.data?.id) {
        await updateCommunityUserRoleUseCase(
          urlParams.id as string,
          userResponse?.data?.id,
          values.role
        );
        await updateCommunityUserRoleUseCase(
          fandomId as string,
          userResponse?.data?.id,
          values.role
        );
      }
      notification.success({
        message: "Community user role updated successfully",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message,
        description: error?.response?.status,
      });
    }
  }

  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = useParams();

  const [users, setUsers] = useState<any>();
  const { tableProps, tableQueryResult } = useTable<any>({
    resource: "communityRoles",
    pagination: { mode: "off" },
    queryOptions: {
      retry: 0,
    },
  });

  useEffect(() => {
    if (tableQueryResult?.data?.data) {
      setUsers(tableQueryResult?.data?.data);
    }

    if (
      // @ts-ignore
      tableQueryResult?.data?.data &&
      // @ts-ignore
      tableQueryResult.data.data.length === 0
    ) {
      const pathWithoutQueryParams = location.pathname;
      navigate(pathWithoutQueryParams);
    }
  }, [tableQueryResult?.data?.data, navigate, location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      if (urlParams.id && !fandomId) {
        const fandomIdRequest = await getCommunityFandomIdUseCase(urlParams.id);
        setFandomId(fandomIdRequest.data.id);
      }
    };

    fetchData();
  }, [urlParams.id]);

  return (
    <>
      <Edit
        breadcrumb={
          <div style={{ display: "flex", columnGap: "10px" }}>
            <ArrowLeftOutlined
              style={{ color: "gray", cursor: "pointer" }}
              onClick={() => {
                navigate("/communities");
              }}
            />
            <Breadcrumb
              items={[
                {
                  title: "Communities",
                  href: "/communities",
                },
                {
                  title: "Roles",
                },
              ]}
            />
          </div>
        }
        headerProps={{ title: "Update a user's role for community" }}
        saveButtonProps={{ style: { display: "none" } }}
        canDelete={false}
        headerButtons={<></>}
      >
        <Form {...formProps} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                validator: async (_, value: string) => {
                  const validator = new UsernameValidator();
                  if (validator.validate(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(validator.errorMessage()));
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
                validator: async (_, value: string) => {
                  const validator = new FollowerRoleValidator();
                  if (validator.validate(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(validator.errorMessage()));
                },
              },
            ]}
          >
            <Select>
              <Select.Option value={FollowerRole.ADMIN}>Admin</Select.Option>
              <Select.Option value={FollowerRole.MODERATOR}>
                Moderator
              </Select.Option>
              <Select.Option value={FollowerRole.MEMBER}>Member</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              icon={<SaveOutlined />}
              type="primary"
              size="large"
              htmlType="submit"
              style={{ float: "right" }}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Edit>
      <div style={{ marginTop: "2rem" }} />
      <List title="Community Staff Users">
        <Table
          {...tableProps}
          dataSource={users}
          rowKey="id"
          key="table"
          pagination={false}
        >
          <Table.Column
            dataIndex="image"
            title="Image"
            align="center"
            render={(value) =>
              value?.webp?.thumbnail && (
                <ImageField
                  value={value.webp.thumbnail}
                  width={50}
                  preview={{ src: value?.webp?.main }}
                />
              )
            }
          />
          <Table.Column dataIndex="username" title="Username" />
          <Table.Column dataIndex="id" title="ID" width={350} />
          <Table.Column
            title="Role"
            render={(record) => (
              <Button
                className={"ant-btn-default"}
                onClick={() => {
                  formProps.form?.setFieldsValue({
                    username: record.username,
                    role: record.role,
                  });
                }}
                style={{ minWidth: 120 }}
              >
                {(record.role === FollowerRole.ADMIN && "Admin") ||
                  (record.role === FollowerRole.MODERATOR && "Moderator") ||
                  (record.role === FollowerRole.MEMBER && "Member")}
                <EditOutlined style={{ color: "black" }} />
              </Button>
            )}
          />
          <Table.Column
            title="Moderators config"
            render={(record) => {
              if (record.role === FollowerRole.MODERATOR) {
                return (
                  <Button
                    className={"ant-btn-default"}
                    onClick={() => {
                      navigate(
                        `/communities/${urlParams.id}/roles/users/${record.id}/moderator-config`
                      );
                    }}
                  >
                    <EditOutlined style={{ color: "black" }} />
                  </Button>
                );
              }
            }}
          />
        </Table>
      </List>
    </>
  );
};
