import axios from "axios";
import { apiUrl } from "../../App";

export async function getCommunityReportsListUseCase(
  communityId: string,
  prev: string,
  next: string
) {
  let queryParams = "";
  if (prev) {
    queryParams += `?next=${prev}`;
  }
  if (next) {
    queryParams += `?prev=${next}`;
  }

  const baseUrl = `${apiUrl}/admin/chat/channels/${communityId}/reports`;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  const mainResults = await axios.get(`${baseUrl}${queryParams}`, config);
  const resultsData = [...mainResults.data];

  let nextId = null;
  let prevId = null;

  let nextResults = null;
  let prevResults = null;

  if (resultsData.length) {
    prevId = mainResults.headers["prev-cursor"];
    nextResults = await axios.get(`${baseUrl}?prev=${prevId}`, config);

    nextId = mainResults.headers["next-cursor"];
    prevResults = await axios.get(`${baseUrl}?next=${nextId}`, config);
  }

  if (resultsData.length && prev) {
    resultsData.reverse();
  }

  return {
    data: mainResults.data,
    cursor: {
      next: nextResults?.data?.length ? prevId : null,
      prev: prevResults?.data?.length ? nextId : null,
    },
  };
}
