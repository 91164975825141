import axios from "axios";
import { apiUrl } from "../../App";

export async function getCommunityUseCase(id: string) {
  return await axios.get(`${apiUrl}/admin/communities/${id}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  });
}
