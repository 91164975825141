import React from "react";
import { NotificationProvider } from "@refinedev/core";
import { notification } from "antd";

export const notificationProvider: NotificationProvider = {
    open: ({
               key,
               description,
               type,
           }) => {
        if (type === "progress") {
            notification.open({
                key,
                description:  "",
                message: null,
                duration: 0,
                closeIcon: <></>,
            });
        } else {
            notification.open({
                key,
                description: "",
                message: description ?? null,
                type,
            });
        }
    },
    close: (key) => notification.destroy(key),
};
