import axios from "axios";
import { apiUrl } from "../../App";

export async function getcommunitySessionsTermsAndConditionsListUseCase(
  id: string,
  prev: string,
  next: string
) {
  if (!id) {
    return {
      data: [],
      cursor: {},
    };
  }
  let queryParams = "?termsAndConditions=true";
  if (prev) {
    queryParams += `&next=${prev}`;
  }
  if (next) {
    queryParams += `&prev=${next}`;
  }

  const baseUrl = `${apiUrl}/admin/chat/channels/${id}/messages`;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  const mainResults = await axios.get(`${baseUrl}${queryParams}`, config);
  const resultsData = [...mainResults.data];

  let nextId = null;
  let prevId = null;

  let nextResults = null;
  let prevResults = null;

  if (resultsData.length) {
    prevId = mainResults.headers["prev-cursor"];
    nextResults = await axios.get(
      `${baseUrl}?termsAndConditions=true&prev=${prevId}`,
      config
    );

    nextId = mainResults.headers["next-cursor"];
    prevResults = await axios.get(
      `${baseUrl}?termsAndConditions=true&next=${nextId}`,
      config
    );
  }

  if (resultsData.length) {
    resultsData.reverse();
  }

  return {
    data: resultsData || [],
    cursor: {
      next: nextResults?.data?.length ? prevId : null,
      prev: prevResults?.data?.length ? nextId : null,
    },
  };
}
