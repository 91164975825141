import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { Edit, useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import {
  Button,
  Checkbox,
  Form,
  Image,
  Input,
  Upload,
  notification,
} from "antd";
import EditPhoneForm from "components/EditPhoneForm";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserPhoneUseCase } from "usecases/user/updateUserPhoneUseCase";
import { updateUserProfileImage } from "usecases/user/updateUserProfileImageUseCase";
import { updateUserUsernameUseCase } from "usecases/user/updateUserUsernameUseCase";
import { updateUserVerifiedUseCase } from "usecases/user/updateUserVerifiedUseCase";
import { UserUpdateDTO } from "../../interfaces";
import { UsernameValidator } from "../../services/user/UsernameValidator";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, form } = useForm<UserUpdateDTO>({
    redirect: "show",
    warnWhenUnsavedChanges: false,
    queryOptions: {
      retry: 0,
    },
  });

  const navigate = useNavigate();

  if (!formProps?.initialValues?.id) {
    navigate("/users/find");
  }

  const [userId, setUserId] = useState(null);
  const [isFileFormatValid, setIsFileFormatValid] = React.useState(true);
  const [isFileSizeValid, setIsFileSizeValid] = React.useState(true);
  const [uploadedFile, setUploadedFile] = React.useState<File | null>(null);

  useEffect(() => {
    if (!userId) {
      setUserId(formProps?.initialValues?.id);
    }
  }, [userId, formProps.initialValues]);

  const handleFormSubmit = async (values: any) => {
    if (!isFileFormatValid) {
      notification.error({
        message: "Invalid file type",
        description: "You can only upload .jpg file!",
      });
      return false;
    }
    if (!isFileSizeValid) {
      notification.error({
        message: "Invalid size",
        description: "Please upload a file no larger than 2MB.",
      });
      return false;
    }
    if (uploadedFile) {
      form.setFieldsValue({
        image: uploadedFile,
      });
    }

    return true;
  };

  async function onFinish(values: any) {
    if (!userId) {
      throw new Error("User id not found");
    }
    try {
      if (formProps?.initialValues?.username !== values.username) {
        await updateUserUsernameUseCase(userId, { username: values.username });
        notification.success({
          message: "User username updated successfully",
        });
      }

      if (formProps?.initialValues?.verified !== values.verified) {
        await updateUserVerifiedUseCase(userId, {
          verified: values.verified,
        });
        notification.success({
          message: "User verified status updated successfully",
        });
      }

      if (values.image instanceof File) {
        await updateUserProfileImage(userId, values.image);
      }

      if (formProps?.initialValues?.phone !== values.phone) {
        await updateUserPhoneUseCase(userId, {
          phone: values.phone,
          isoCode: values.isoCode,
        });
        notification.success({
          message: "User phone updated successfully",
        });
      }

      navigate("/users/show/" + values.username);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message,
        description: error?.response?.status,
      });
    }
  }

  return (
    <Edit
      saveButtonProps={{ style: { display: "none" } }}
      canDelete={false}
      headerButtons={<></>}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={onFinish}
        onSubmitCapture={handleFormSubmit}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: false,
              validator: async (_, value: string) => {
                const validator = new UsernameValidator();
                if (validator.validate(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(validator.errorMessage()));
              },
            },
          ]}
        >
          <Input />
        </Form.Item>

        <EditPhoneForm form={form} formProps={formProps} />
        <Form.Item
          label="Verified"
          name="verified"
          valuePropName="checked"
          rules={[
            {
              required: false,
            },
          ]}
          initialValue={true}
        >
          <Checkbox title={"Verified"} name="verified" defaultChecked={true} />
        </Form.Item>
        <div
          style={{
            marginTop: "5px",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>Image</span>
          {!uploadedFile && formProps.initialValues?.image?.webp?.main && (
            <Image
              src={formProps.initialValues?.image?.webp?.main}
              width={200}
              style={{ marginTop: "10px" }}
            />
          )}
        </div>
        <Form.Item name="image">
          <Upload
            name="image"
            listType="picture"
            accept=".jpg"
            maxCount={1}
            beforeUpload={(file) => {
              if (file.type !== "image/jpeg" || !file.name.endsWith(".jpg")) {
                setIsFileFormatValid(false);
                return true; // Unexpectedly, this will show file in red color as an error
              }

              if (file.size > 2 * 1024 * 1024) {
                setIsFileSizeValid(false);
                return true; // Unexpectedly, this will show file in red color as an error
              }

              setUploadedFile(file);

              setIsFileSizeValid(true);
              setIsFileFormatValid(true);
              return false; // Unexpectedly, this will show file in gray color as a success
            }}
            onRemove={() => {
              setUploadedFile(null);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        <div
          style={{
            marginTop: "-10px",
            marginBottom: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: "12px" }}>
            Only <b>.jpg</b> files are permitted for the image. (.jpeg, .png,
            .webp and any other extension <b>are NOT allowed</b>)
          </span>
          <span style={{ fontSize: "12px" }}>
            Maximum file size is <b>2MB</b>.
          </span>
        </div>
        <Form.Item>
          <Button
            icon={<SaveOutlined />}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ float: "right" }}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Edit>
  );
};
