export enum FollowerRole {
    ADMIN = 'admin',
    MODERATOR = 'moderator',
    MEMBER = 'member',
}

export class FollowerRoleValidator {
    validate(role: string): boolean {
        return Object.values(FollowerRole).includes(role as FollowerRole)
    }

    errorMessage(): string {
        return 'Role must be admin, moderator or member.'
    }
}