import axios from "axios";
import { apiUrl } from "../../App";

export async function getCommunityUserModeratorConfigUseCase(
  communityId: string,
  userId: string
) {
  const url = `${apiUrl}/admin/chat/channels/${communityId}/users/${userId}/moderator-config`;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  return axios.get(url, config);
}
