import axios from "axios";
import {apiUrl} from "../../App";

export async function updateCommunityImage(communityId: string, image: File) {
    if (!image.name?.toLowerCase().endsWith('.jpg')) {
        throw new Error("Only .jpg files are allowed");
    }

    // Step 1: Post to get the image upload URL
    const imageUploadURLResponse = await axios.post(
        `${apiUrl}/admin/communities/${communityId}/image`,
        {},
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
        }
    );

    // Step 2: Put the image data to the obtained URL
    await axios.put(
        imageUploadURLResponse.data.url,
        image,
        {
            headers: {
                'Content-Type': 'image/jpeg',
            },
        }
    );
}
