import axios from "axios";
import { apiUrl } from "../../App";

export async function updateCommunityOwner(
  communityId: string,
  ownerId: string
) {
  await axios.patch(
    `${apiUrl}/admin/communities/${communityId}/owner`,
    { ownerId },
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}
