import axios from "axios";
import { apiUrl } from "../../App";

export async function removeCommunitySessionMessageUseCase(
  sessionId: string,
  messageId: string
) {
  const url = `${apiUrl}/admin/chat/sessions/${sessionId}/messages/${messageId}`;
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  return axios.delete(url, config);
}
